import { Document, TEMPLATE_TYPE, User } from "@models";
import { ClickAwayListener } from "@mui/material";
import { getDocTypeStatus, isStatusComplete } from "@utils";
import { useSearchParams } from "react-router-dom";
import { RoadmapCard, RoadmapCardId, RoadmapStageId } from "../../config";

type Props = {
  roadmap: {
    title: string;
    id: RoadmapStageId;
    disabled?: boolean;
    cards: RoadmapCard[];
  }[];
  documents: Document[];
  user: User;
  onClickOpenCardDialog: (
    cardId: RoadmapCardId,
    stageId: RoadmapStageId
  ) => void;
};

export const Cards = (props: Props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const highlightedCard = searchParams.get("card");

  const { roadmap, onClickOpenCardDialog } = props;

  return (
    <div className="flex">
      {roadmap.map((step) => {
        return (
          <div className="mt-3 flex w-1/5 flex-col gap-y-2" key={step.title}>
            {step.cards.map((card) => {
              const cardDocumentsTemplateIds = card.tasks.map(
                (task) => task.id
              );

              const cardDocuments = props.documents.filter((document) =>
                cardDocumentsTemplateIds.includes(
                  document.name as TEMPLATE_TYPE
                )
              );

              const areAllCardDocumentsCompleted =
                cardDocuments.length &&
                cardDocuments.every((document) =>
                  isStatusComplete(
                    getDocTypeStatus({
                      templateId: document.name as TEMPLATE_TYPE,
                      user: props.user,
                      documents: props.documents,
                    })
                  )
                );

              const disabled = step.disabled || card.enabled === false;

              let background = areAllCardDocumentsCompleted
                ? "bg-green-600"
                : "bg-slate-200";
              let backgroundHover = areAllCardDocumentsCompleted
                ? "hover:bg-green-700"
                : "hover:bg-slate-300";

              const textColor = areAllCardDocumentsCompleted
                ? "text-white"
                : "text-default";

              const isHighlighted = highlightedCard === card.title;

              return (
                <div
                  key={card.title}
                  className="relative cursor-pointer"
                  onClick={() => {
                    !disabled && onClickOpenCardDialog(card.id, step.id);
                    searchParams.delete("card");
                    setSearchParams(searchParams);
                  }}
                >
                  <div
                    key={card.title}
                    className={`
                      ${background} 
                      ${textColor} 
                      ${backgroundHover} 
                      relative mr-[6px] 
                      flex h-28 
                      cursor-pointer 
                      items-center 
                      justify-center 
                      gap-x-2 
                      rounded-sm 
                      p-3 
                      text-center 
                    `}
                  >
                    {disabled && (
                      <div className="absolute bottom-0 left-0 right-0 top-0 z-10 bg-slate-100/60"></div>
                    )}
                    {card.title}
                  </div>
                  {isHighlighted && (
                    <ClickAwayListener
                      onClickAway={() => {
                        searchParams.delete("card");
                        searchParams.delete("task");
                        setSearchParams(searchParams);
                      }}
                    >
                      <div
                        data-testid={`${card.id}-highlight`}
                        className={`absolute w-[calc(100%_-_6px)] h-full top-0 motion-safe:animate-fade animate-infinite animate-ease-in animate-fill-forwards ring ring-[#074EE8] rounded-sm`}
                      />
                    </ClickAwayListener>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

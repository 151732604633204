import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel as MuiFormControlLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";

import { useCreateDeviceMutation } from "@hooks";
import {
  ComponentConfigurationType,
  ComponentType,
  Device,
  Plan,
  SimpleSubscription,
  SubComponentConfigurationType,
  SubComponentType,
  User,
} from "@models";
import { isPaidDeviceCreation } from "@utils";
import {
  ComponentState,
  NewDeviceUIState,
  SubComponentState,
} from "../../types";
import { FreePlanLimitModal } from "./FreePlanLimitModal";
import { HardwareComponentConfiguration } from "./HardwareComponentConfiguration";
import { SoftwareComponentConfiguration } from "./SoftwareComponentConfiguration";
import { YesNo } from "./YesNo";
import {
  getCleanDevice,
  initialElectricalSubComponentState,
  initialSoftwareSubComponentState,
  isFormInvalid,
  newHardwareComponentInitialState,
  newProductInitialState,
  newSoftwareComponentInitialState,
} from "./utils";

export const DeviceCreationModal = ({
  open,
  onClose,
  subscription,
  user,
  devices,
}: {
  open: boolean;
  onClose: () => void;
  subscription: SimpleSubscription;
  user: User;
  devices: Device[];
}) => {
  const [newDevice, setNewDevice] = useState<NewDeviceUIState>(
    newProductInitialState
  );

  const updateProductComponent = (component: ComponentState) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id ? component : c
      ),
    });
  };

  const updateSubComponent = (
    component: ComponentState,
    subComponent: SubComponentState
  ) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id
          ? {
              ...c,
              subComponents: c.subComponents.map((sc) =>
                sc.id === subComponent.id ? subComponent : sc
              ),
            }
          : c
      ),
    });
  };

  const toggleProductComponentCollapse = (component: ComponentState) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id ? { ...c, collapsed: !c.collapsed } : c
      ),
    });

    scrollToComponent(component);
  };

  const scrollToComponent = (component: ComponentState) => {
    setTimeout(() => {
      const element = document.getElementById(`component-${component.id}`);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const getComponentConfigurationValue = (
    component: ComponentState,
    type: ComponentConfigurationType
  ) => {
    const value = component.configuration.find(
      (item) => item.type === type
    )?.value;
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return value;
    }
  };

  const getSubComponentConfigurationValue = (
    subComponent: SubComponentState,
    type: SubComponentConfigurationType
  ) => {
    return subComponent.configuration.find((item) => item.type === type)?.value;
  };

  const hasHardware = newDevice.components.some(
    (c) => c.type === ComponentType.HARDWARE
  );

  const hasSoftware = newDevice.components.some(
    (c) => c.type === ComponentType.SOFTWARE
  );

  const addSubComponent = (
    type: SubComponentType,
    component: ComponentState,
    name: string
  ) => {
    const initialState =
      type === SubComponentType.SOFTWARE
        ? initialSoftwareSubComponentState
        : type === SubComponentType.ELECTRICAL
          ? initialElectricalSubComponentState
          : {
              type,
              name,
              description: "",
              configuration: [],
            };
    const newSubComponent = {
      id: component.subComponents.length + 1,
      ...initialState,
    };
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id
          ? { ...c, subComponents: [...c.subComponents, newSubComponent] }
          : c
      ),
    });
  };

  const removeAllSubComponentsByType = (
    component: ComponentState,
    type: SubComponentType
  ) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.map((c) =>
        c.id === component.id
          ? {
              ...c,
              subComponents: c.subComponents.filter((sc) => sc.type !== type),
            }
          : c
      ),
    });
  };

  const removeAllComponentsByType = (type: ComponentType) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.filter((c) => c.type !== type),
    });
  };

  const updateComponentConfigurationValue = (
    component: ComponentState,
    type: ComponentConfigurationType,
    value: string
  ) => {
    updateProductComponent({
      ...component,
      // If the configuration already contains the item, update it, otherwise add it
      configuration: component.configuration.some((item) => item.type === type)
        ? component.configuration.map((item) =>
            item.type === type ? { ...item, value } : item
          )
        : [...component.configuration, { type, value }],
    });
  };

  const getHighestId = (components: ComponentState[]) => {
    return components.reduce(
      (max, component) => Math.max(max, component.id),
      0
    );
  };

  const addProductComponent = (type: ComponentType) => {
    const initialState =
      type === ComponentType.HARDWARE
        ? newHardwareComponentInitialState
        : newSoftwareComponentInitialState;

    const newComponent = {
      id: getHighestId(newDevice.components) + 1,
      ...initialState,
    };
    setNewDevice({
      ...newDevice,
      components: [
        ...newDevice.components.map((c) => ({ ...c, collapsed: true })),
        newComponent,
      ],
    });

    scrollToComponent(newComponent);
  };

  const removeProductComponent = (component: ComponentState) => {
    setNewDevice({
      ...newDevice,
      components: newDevice.components.filter((c) => c.id !== component.id),
    });
  };

  const updateSubComponentConfigurationValue = (
    component: ComponentState,
    subComponent: SubComponentState,
    type: SubComponentConfigurationType,
    value: string
  ) => {
    updateSubComponent(component, {
      ...subComponent,
      configuration: subComponent.configuration.some(
        (item) => item.type === type
      )
        ? subComponent.configuration.map((item) =>
            item.type === type ? { ...item, value } : item
          )
        : [...subComponent.configuration, { type, value }],
    });
  };

  const createDeviceMutation = useCreateDeviceMutation();

  const [billingConfirmed, setBillingConfirmed] = useState(false);

  const isFreePlan = subscription.product === Plan.FREE;

  const isPaidCreation = isPaidDeviceCreation(user, devices.length);

  if (isFreePlan && devices.length >= 1) {
    return <FreePlanLimitModal open={open} onClose={onClose} />;
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Device</DialogTitle>
      <DialogContent className="flex  flex-col gap-y-4">
        <TextField
          label="Device Name"
          required
          fullWidth
          value={newDevice?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewDevice({ ...newDevice, name: event.target.value })
          }
        />
        <div>
          <Alert severity="info" className="mb-2">
            Provide a detailed description of your device. Every detail, like
            special features, materials, or the users and patients can be
            essential in order to give you the best possible suggestions in the
            future.
          </Alert>
          <TextField
            label="Description"
            required
            fullWidth
            multiline
            minRows={3}
            value={newDevice?.description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewDevice({ ...newDevice, description: event.target.value });
            }}
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <div>
            <h3>Hardware</h3>
            <Alert severity="info" className="mb-2">
              A hardware component is a standalone physical part of the device
              that might contain electrical, mechanical , firmware or software
              parts. Examples are a measuring device, a smartwatch or a
              pacemaker.
            </Alert>
            <div>
              <YesNo
                question="Does the product have hardware components?"
                value={!!hasHardware}
                onChange={(value) =>
                  value === "true"
                    ? addProductComponent(ComponentType.HARDWARE)
                    : removeAllComponentsByType(ComponentType.HARDWARE)
                }
              />
            </div>
            {!!hasHardware && (
              <HardwareComponentConfiguration
                newDevice={newDevice}
                toggleProductComponentCollapse={toggleProductComponentCollapse}
                updateProductComponent={updateProductComponent}
                updateComponentConfigurationValue={
                  updateComponentConfigurationValue
                }
                getComponentConfigurationValue={getComponentConfigurationValue}
                getSubComponentConfigurationValue={
                  getSubComponentConfigurationValue
                }
                addSubComponent={addSubComponent}
                removeAllSubComponentsByType={removeAllSubComponentsByType}
                updateSubComponent={updateSubComponent}
                updateSubComponentConfigurationValue={
                  updateSubComponentConfigurationValue
                }
                removeProductComponent={removeProductComponent}
                addProductComponent={addProductComponent}
              />
            )}
          </div>
          {/* <Divider className="mt-3 mb-3" /> */}
          <div>
            <h3>Standalone Software</h3>
            <Alert severity="info" className="mb-2">
              A standalone software component might be a mobile app, a web app,
              or a software package that is not part of any hardware. One
              component covers the client and server side.
            </Alert>
            <YesNo
              question="Does the product have standalone software components?"
              value={!!hasSoftware}
              onChange={(value) =>
                value === "true"
                  ? addProductComponent(ComponentType.SOFTWARE)
                  : removeAllComponentsByType(ComponentType.SOFTWARE)
              }
            />
            {!!hasSoftware && (
              <SoftwareComponentConfiguration
                newDevice={newDevice}
                toggleProductComponentCollapse={toggleProductComponentCollapse}
                updateProductComponent={updateProductComponent}
                updateComponentConfigurationValue={
                  updateComponentConfigurationValue
                }
                getComponentConfigurationValue={getComponentConfigurationValue}
                removeProductComponent={removeProductComponent}
                addProductComponent={addProductComponent}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flex gap-x-4">
        {isPaidCreation && (
          <MuiFormControlLabel
            control={
              <Checkbox
                className="ml-1"
                checked={billingConfirmed}
                onChange={(e) => setBillingConfirmed(e.target.checked)}
              />
            }
            label="I confirm that I will be billed for this additional device"
            labelPlacement="start"
          />
        )}
        <LoadingButton
          disabled={
            isFormInvalid(newDevice) || (isPaidCreation && !billingConfirmed)
          }
          variant="contained"
          color="success"
          loading={createDeviceMutation.isPending}
          onClick={() =>
            createDeviceMutation.mutate({ device: getCleanDevice(newDevice) })
          }
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

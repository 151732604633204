import { chain } from "lodash";
import Papa from "papaparse";
import { ASSISTANT_CONFIG } from "../../config";
import {
  Document,
  DocumentAnswer,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "../../stores/models";
import {
  DocumentDataKey,
  MultiStepFormInputElement,
  SuggestionState,
  TableElement,
  TemplateElement,
} from "../../types";
import { captureSentryException, loadDocumentReferences } from "../../utils";

export const findElementAnswer = ({
  answers,
  elementId,
}: {
  answers: DocumentAnswer[];
  elementId: string;
}) => answers.find((answer) => answer.element === elementId);

export function getSuggestion(
  step: TemplateElement,
  documentVersion: DocumentVersion,
  suggestions: SuggestionState
) {
  const suggestionState = suggestions[step.id];
  if (!suggestionState) {
    throw new Error(
      "Error trying to access suggestion. A suggestion with the given id does not exist in the state."
    );
  }

  const { value: localSuggestion } = suggestionState;

  const savedSuggestion = chain(documentVersion.suggestions)
    .filter((s) => s.element === step.id)
    .orderBy("createdAt")
    .last()
    .value()?.suggestion;

  return localSuggestion || savedSuggestion;
}

export function getAnswerFromDocumentVersion(
  step: MultiStepFormInputElement,
  documentVersion: DocumentVersion
): string | undefined | null {
  return documentVersion.answers.find(
    (s: DocumentAnswer) => s.element === step.id
  )?.answer;
}

export const getAnswer = (
  templateId: TEMPLATE_TYPE,
  currentAnswer: string | null | undefined,
  step: TemplateElement,
  documentVersion: DocumentVersion,
  documents: Document[],
  forceRunTransformer: boolean = false
) => {
  let answer = "";

  const savedAnswer = getAnswerFromDocumentVersion(
    step.element,
    documentVersion
  );

  if (
    "transformerConfig" in step.element &&
    (forceRunTransformer || step.element.transformerConfig?.type === "always")
  ) {
    answer = runTransformer(templateId, step, documentVersion, documents);
    // If the answer is defined in the state, use that
  } else if (typeof currentAnswer === "string") {
    answer = currentAnswer as string;
    // If the answer saved in the document, use that
  } else if (savedAnswer) {
    answer = savedAnswer;
  } else if (
    !savedAnswer &&
    "transformerConfig" in step.element &&
    step.element.transformerConfig?.type === "default"
  ) {
    answer = runTransformer(templateId, step, documentVersion, documents);

    // Else use the default answer that the state has been initialized with
  } else if (step.element.options.default) {
    answer = step.element.options.default;
  }
  return answer;
};

const runTransformer = (
  templateId: TEMPLATE_TYPE,
  step: TemplateElement,
  documentVersion: DocumentVersion,
  documents: Document[]
) => {
  // load data
  if (!step.element.transformerConfig) {
    throw new Error(
      `No transformerConfig defined for element ${step.element.id}`
    );
  }
  const inputs = step.element.transformerConfig.inputs.map((input) => {
    // check if answer is in current document
    let answerForInput = documentVersion.answers.find(
      (a) => a.element === input
    )?.answer;

    // check if answer is in any document
    if (!answerForInput) {
      answerForInput = documents
        .find((doc) =>
          doc.versions[0]?.answers.find((a) => a.element === input)
        )
        ?.versions[0]?.answers.find((a) => a.element === input)?.answer;
    }

    if (!answerForInput) {
      // TODO handle this better. This is not ideal and ideally the step should only render if all previous steps are completed. We had to remove the error throw because it was causing issues since some inputs are relying on previous inputs and since the transformer is run on every render, it was causing issues.
      answerForInput = "";
      // throw new Error(`No answer found for input ${input}`);
    }
    return { id: input, value: answerForInput };
  });

  const inputsObject: Record<DocumentDataKey, string> = chain(inputs)
    .keyBy("id")
    .mapValues("value")
    .value() as Record<DocumentDataKey, string>;

  if (
    step.element.transformerConfig.inputs.some(
      (key: DocumentDataKey) => inputsObject[key] === undefined
    )
  ) {
    const message = `Missing required data (utils): ${JSON.stringify(
      inputsObject
    )}`;
    console.error(message);
    captureSentryException(message);
    return "";
  }

  const docReferences = loadDocumentReferences(templateId, documents);

  const output = step.element.transformerConfig?.transformer(
    inputsObject,
    docReferences
  );

  return output;
};

interface CSVRow {
  [key: string]: unknown;
}

export const handleCSVUpload = (
  file: File,
  templateId: TEMPLATE_TYPE,
  elementId: DocumentDataKey
): Promise<string> => {
  const element = ASSISTANT_CONFIG[templateId].elements
    .filter((e): e is TemplateElement => e.hasOwnProperty("element"))
    .find((e) => e.id === elementId && e.element.type === "table");

  if (!element) {
    throw new Error("Element is not a table element");
  }

  const templateElement = element?.element as TableElement;

  const expectedColumns = templateElement.options.columns;

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: (results) => {
        const headers = Object.keys(results.data[0] as CSVRow).map((header) =>
          header.trim()
        );

        if (headers.length !== expectedColumns.length) {
          reject(
            new Error(
              `Too many columns in the CSV. Expected ${expectedColumns.length}, but found ${headers.length}.`
            )
          );
          return;
        }

        // Check if all expected columns are present (ignoring order)
        const missingColumns = expectedColumns
          .filter((col) => !headers.includes(col.name))
          .map((col) => col.name);
        if (missingColumns.length > 0) {
          reject(new Error(`Missing columns: ${missingColumns.join(", ")}`));
          return;
        }

        // Generate markdown table
        let markdownTable =
          "| " + expectedColumns.map((col) => col.name).join(" | ") + " |\n";
        markdownTable +=
          "| " + expectedColumns.map(() => "---").join(" | ") + " |\n";

        results.data.forEach((row) => {
          const typedRow = row as CSVRow;

          markdownTable +=
            "| " +
            expectedColumns.map((col) => typedRow[col.name] || "").join(" | ") +
            " |\n";
        });
        resolve(markdownTable);
      },
      error: (error) => {
        reject(new Error(`Error parsing CSV: ${error.message}`));
      },
      header: true,
      skipEmptyLines: true,
    });
  });
};

import { useInviteUserMutation } from "@hooks";
import { useState } from "react";
import { useSnackbar } from "../../Snackbar";

export const emptyUser = { firstName: "", lastName: "", email: "" };

export type InvitedUser = {
  email: string;
  firstName: string;
  lastName: string;
};

const invitedUserMessageDictionary = (inviteeFirstName: string) => ({
  success: {
    text: `Invitation has been sent to ${inviteeFirstName}'s email inbox. You can now select ${inviteeFirstName} as an assignee. However they will need to sign up using the link in their email in order to approve the document.`,
    color: "green",
  },
  conflict: {
    text: "An error occurred, are you sure you entered the correct email?",
    color: "red",
  },
  error: {
    text: "Something went wrong, please try again later.",
    color: "red",
  },
});

export type InviteUserProps = {
  inviteUserResponse: InviteUserResponse;
};

export type InviteUserResponse = {
  invitedUser: InvitedUser;
  setInvitedUser: (invitedUser: InvitedUser) => void;
  openInviteUserModal: boolean;
  setOpenInviteUserModal: (open: boolean) => void;
  handleInviteUser: () => void;
  handleCloseInviteUserModal: () => void;
  areAllInviteUserInputsFilled: boolean;
};

export default function useInviteUser({
  orgId,
}: {
  orgId: string;
}): InviteUserProps {
  const [invitedUser, setInvitedUser] = useState<InvitedUser>(emptyUser);
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const { showSnackbar } = useSnackbar();

  const inviteUserMutation = useInviteUserMutation(orgId);

  const handleCloseInviteUserModal = () => {
    setOpenInviteUserModal(false);
  };

  const handleInviteUser = async () => {
    const waitMutation = await inviteUserMutation.mutateAsync({
      inviteeEmail: invitedUser.email.trim(),
      inviteeFirstName: invitedUser.firstName.trim(),
      inviteeLastName: invitedUser.lastName.trim(),
    });

    const isMutationConflict = waitMutation.status === 409;
    const isMutationSuccessful = waitMutation.status === 200;

    const inviteeFirstName = invitedUser.firstName.trim();

    let message = invitedUserMessageDictionary(inviteeFirstName).success;

    if (isMutationConflict) {
      message = invitedUserMessageDictionary(inviteeFirstName).conflict;
    }

    if (!isMutationSuccessful && !isMutationConflict) {
      message = invitedUserMessageDictionary(inviteeFirstName).error;
    }

    showSnackbar(message.text);
    setOpenInviteUserModal(false);
    setInvitedUser(emptyUser);
  };

  const areAllInviteUserInputsFilled =
    !!invitedUser?.email && !!invitedUser?.firstName && !!invitedUser?.lastName;

  return {
    inviteUserResponse: {
      invitedUser,
      setInvitedUser,
      openInviteUserModal,
      setOpenInviteUserModal,
      handleInviteUser,
      handleCloseInviteUserModal,
      areAllInviteUserInputsFilled,
    },
  };
}

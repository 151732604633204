import { ASSISTANT_CONFIG, documentIsHidden } from "../config";
import {
  Device,
  Document,
  DocumentVersion,
  DocumentVersionApproverStatus,
  TEMPLATE_TYPE,
  User,
} from "../stores/models";
import {
  DataReferenceElement,
  DocumentDataKey,
  DocumentMetadata,
  TemplateElement,
} from "../types";

import Handlebars from "handlebars";
import moment from "moment";
import { getFile } from "src/services";
import { Entries } from "type-fest";
import {
  getDocVersionRevisionNumber,
  getDocVersionsWithStatus,
  getDocumentIdentifier,
  getElementConfigByDataKey,
  hasHardware,
  hasSoftware,
  isSoftwareOnly,
  loadDocumentReferences,
} from ".";

// Register handlebars helpers
Handlebars.registerHelper("ifEquals", (arg1, arg2, options) => {
  return arg1 == arg2 ? options.fn() : options.inverse();
});

const reduceOp = function (args: any, reducer: (a: any, b: any) => boolean) {
  args = Array.from(args);
  args.pop(); // => options
  var first = args.shift();
  return args.reduce(reducer, first);
};

Handlebars.registerHelper({
  eq: function () {
    return reduceOp(arguments, (a, b) => a === b);
  },
  ne: function () {
    return reduceOp(arguments, (a, b) => a !== b);
  },
  lt: function () {
    return reduceOp(arguments, (a, b) => a < b);
  },
  gt: function () {
    return reduceOp(arguments, (a, b) => a > b);
  },
  lte: function () {
    return reduceOp(arguments, (a, b) => a <= b);
  },
  gte: function () {
    return reduceOp(arguments, (a, b) => a >= b);
  },
  and: function () {
    return reduceOp(arguments, (a, b) => a && b);
  },
  or: function () {
    return reduceOp(arguments, (a, b) => a || b);
  },
});

export const generateStaticDocument = async (
  user: User,
  device: Device,
  type: TEMPLATE_TYPE,
  document: Document,
  selectedDocumentVersion: DocumentVersion,
  documents: Document[]
) => {
  const path = `/templates/md/${type}.md`;
  const { href } = new URL(path, import.meta.url);
  const response = await fetch(href);
  const body = await response.text();
  const metadata = getDocumentMetadata(
    type,
    user,
    document,
    selectedDocumentVersion,
    documents
  );

  const documentReferences: Record<string, string> = loadDocumentReferences(
    type,
    documents
  );

  const referenceData = loadReferenceEntities(type, device);
  const dependencyData = loadDependencies(type, documents);
  const formattedDependencyData = await formatDependencyData(dependencyData);
  const deviceCharacteristics = loadDeviceCharacteristics(device);

  const data = {
    ...referenceData,
    ...formattedDependencyData,
    ...metadata,
    ...deviceCharacteristics,
    ...documentReferences,
  };

  const template = Handlebars.compile(body, { strict: true });
  return template(data);
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const generateDocument = async (
  user: User,
  device: Device,
  document: Document,
  documentVersion: DocumentVersion,
  documents: Document[],
  type: TEMPLATE_TYPE,
  data: Partial<Record<DocumentDataKey, string>>
) => {
  const path = `/templates/md/${type}.md`;
  const { href } = new URL(path, import.meta.url);
  const response = await fetch(href);
  const body = await response.text();

  const templateConfig = ASSISTANT_CONFIG[type];

  const documentReferences: Record<string, string> = loadDocumentReferences(
    type,
    documents
  );

  const referenceData = loadReferenceEntities(type, device);
  const dependencyData = loadDependencies(type, documents);
  const formattedDependencyData = await formatDependencyData(dependencyData);
  const deviceCharacteristics = loadDeviceCharacteristics(device);

  // Merge the data from the reference elements with the data from the document
  data = {
    ...referenceData,
    ...formattedDependencyData,
    ...deviceCharacteristics,
    ...documentReferences,
    ...data,
  };

  const templateElements = templateConfig.elements.filter(
    (e): e is TemplateElement => !e.hasOwnProperty("entity")
  );

  await Promise.all(
    templateElements.map(async (element) => {
      let elementValue = data[element.id];
      const isImage = isAnswerImageFile(documentVersion.answers, element.id);

      if (isImage) {
        const fileResponse = await getFile({ fileId: elementValue || "" });

        if (fileResponse.data) {
          const base64 = await blobToBase64(fileResponse.data);
          elementValue = base64;
        }
      }

      const formattedAnswer = await formatAnswer(element, elementValue);
      data[element.id] = formattedAnswer;
    })
  );

  const metadata = getDocumentMetadata(
    type,
    user,
    document,
    documentVersion,
    documents
  );
  const combinedData = {
    ...data,
    ...metadata,
  };

  const template = Handlebars.compile(body, {
    noEscape: true,
    strict: true,
  });
  return template(combinedData);
};

export const loadReferenceEntities = (type: TEMPLATE_TYPE, device: Device) => {
  const data: Partial<Record<DocumentDataKey, string>> = {};

  const templateConfig = ASSISTANT_CONFIG[type];

  const referenceElements = templateConfig.elements.filter(
    (e): e is DataReferenceElement => e.hasOwnProperty("entity")
  );

  // Load external data that was specified as a dependency element in the assistant config
  referenceElements.forEach((element) => {
    switch (element.entity) {
      case "Device":
        data[element.id] = device[element.path as keyof Device].toString();
        break;
      default:
        break;
    }
  });

  return data;
};

export const generateSignatureAndHistoryTables = (
  templateType: TEMPLATE_TYPE,
  user: User,
  document: Document,
  documentVersion: DocumentVersion,
  documents: Document[]
) => {
  const metadata = getDocumentMetadata(
    templateType,
    user,
    document,
    documentVersion,
    documents
  );

  let templateText = `
    ## Document Signature Table
    
    | Process  | Person          | Date            | Initials       |
    | -------- | --------------- | --------------- | -------------- |
    | Creation | {{ createdBy }} | {{ createdAt }} | {{ initials }} |
    {{#each approvers}}
    | Approval | {{ name }}      | {{ approvedAt }} | {{ initials }}|
    {{/each}}
    
    ## Revision History
    
    | Document Number     | Revision | Date | Change History  |
    | ------------------- | -------- | ---- | --------------- |
    {{#each revisions}}
    | {{ ../documentNumber }} | {{ revision }}    | {{ createdAt }} | {{notes}} |
    {{/each}}`;

  // remove indentation
  templateText = templateText.replace(/^\s+/gm, "");

  const template = Handlebars.compile(templateText, { strict: true });
  return template(metadata);
};

export const loadDependencies = (
  type: TEMPLATE_TYPE,
  documents: Document[]
): Partial<Record<DocumentDataKey, string>> => {
  const data: Partial<Record<DocumentDataKey, string>> = {};
  const templateConfig = ASSISTANT_CONFIG[type];

  // Load data from other documents that was specified as a dependency in the assistant config
  templateConfig.dependencies?.forEach((dependency) => {
    const templateRelatedToDependency = Object.entries(ASSISTANT_CONFIG).find(
      ([_, value]) =>
        value.elements.some(
          (element) => "id" in element && element.id === dependency
        )
    );

    if (!templateRelatedToDependency) {
      throw new Error(
        `Could not find template related to dependency ${dependency}`
      );
    }

    const answer = documents
      .find((d) => d.name === templateRelatedToDependency[0])
      ?.versions[0]?.answers.find((a) => a.element === dependency)?.answer;

    const answerIsRequired = templateRelatedToDependency[1].elements.find(
      (e): e is TemplateElement => e.id === dependency
    )?.required;

    if (!answer && answerIsRequired) {
      throw new Error(`Could not find answer for dependency ${dependency}`);
    }

    data[dependency] = answer ?? undefined;
  });

  return data;
};

export const isAnswerImageFile = (
  answers: DocumentVersion["answers"],
  elementId: string
) => {
  return !!answers.find((a) => a.element === elementId)?.answerFileId;
};

export const formatDependencyData = async (
  data: Partial<Record<DocumentDataKey, string>>
) => {
  const formattedData: Partial<Record<DocumentDataKey, string>> = {};
  await Promise.all(
    (Object.entries(data) as Entries<typeof data>).map(async ([key, value]) => {
      const element = getElementConfigByDataKey(key);
      if (!value && element.required) {
        throw new Error(`Could not find answer for dependency ${key}`);
      }
      // Format the answer according to the element type
      const formattedAnswer = await formatAnswer(element, value);
      formattedData[key] = formattedAnswer;
    })
  );
  return formattedData;
};

export const formatAnswer = async (
  element: TemplateElement,
  value: string = ""
): Promise<string> => {
  switch (element.element.type) {
    case "textField":
      const fixed = value
        .replace(/^\s+/gm, "  ") // Fix indentation
        .replace(/\n/g, "<br>"); // Replace newlines with <br> tags
      return fixed;

    case "table":
      // Clean up. Make sure the markdown is formatted correctly with no leading or trailing spaces
      return value
        .split("\n")
        .filter((line) => line.trim() !== "")
        .map((line) => line.trim())
        .join("\n");

    case "fileUpload":
      return `![${element.id}](${value})`;

    default:
      return value;
  }
};

export const loadDeviceCharacteristics = (
  device: Device
): Record<string, boolean> => {
  return {
    "has-software": hasSoftware(device),
    "has-hardware": hasHardware(device),
    "software-only": isSoftwareOnly(device),
  };
};

export const getDocumentMetadata = (
  templateType: TEMPLATE_TYPE,
  user: User,
  document: Document,
  documentVersion: DocumentVersion,
  documents: Document[]
): DocumentMetadata => {
  const templateConfig = ASSISTANT_CONFIG[templateType];
  if (documentIsHidden(templateConfig)) {
    throw new Error("Template config is hidden.");
  }

  const documentIdentifier = getDocumentIdentifier(
    templateType,
    document,
    documentVersion,
    document.versions
  );
  const revision = getDocVersionRevisionNumber(
    documentVersion,
    document.versions
  );
  const documentNumber = document.id;

  return {
    createdAt: moment(documentVersion.createdAt).format("YYYY-MM-DD"),
    approvedAt: moment().format("YYYY-MM-DD"),
    createdBy: (user.firstName || "") + " " + (user.lastName || ""),
    initials: (user.firstName || "")[0] + (user.lastName || "")[0],
    approvers: documentVersion.documentVersionApprover.map((a) => ({
      name: a.user.firstName + " " + a.user.lastName,
      approvedAt:
        a.approvalStatus === DocumentVersionApproverStatus.APPROVED
          ? moment(a.updatedAt).format("YYYY-MM-DD")
          : "Pending Approval",
      initials: (a.user.firstName || "")[0] + (a.user.lastName || "")[0],
    })),
    documentIdentifier,
    documentNumber: documentNumber.toString(),
    ["company-name"]: user.organization.name,
    revision,
    revisions: getDocVersionsWithStatus({
      documents,
      document,
      user,
    })
      .filter((v) => v.revision <= revision)
      .map((v) => ({
        revision: v.revision,
        createdAt: moment(v.createdAt).format("YYYY-MM-DD"),
        // Remove newlines from the revision summary because it breaks the markdown table
        notes: v.revisionSummary.replace(/^\s+/gm, "").replace(/\n/g, " "),
      }))
      .sort((a, b) => a.revision - b.revision),
  };
};

import { Document, User } from "@models";
import {
  EditOutlined,
  HistoryEdu,
  LockOutlined,
  NoteAddOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import { getDocTypeStatus } from "@utils";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../config";
import { DocumentStatus, TemplateAssistant } from "../../../types";
import { DocExplorerLeafContent } from "./DocExplorerLeafContent";

const DocExplorerLeaf = ({
  template,
  deviceId,
  documents,
  user,
}: {
  template: TemplateAssistant;
  deviceId: string;
  user: User;
  documents: Document[];
}) => {
  const docStatus = getDocTypeStatus({
    documents,
    templateId: template.id,
    user,
  });

  // Default: Locked if unsure what to do with item
  if (docStatus === DocumentStatus.LOCKED || !docStatus) {
    return (
      <DocExplorerLeafContent
        template={template}
        title="Locked"
        disabled
        Icon={LockOutlined}
        color="inherit"
      />
    );
  }

  if (docStatus === DocumentStatus.AVAILABLE) {
    return (
      <Link
        to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
          ":templateId",
          template.id
        )}
        className={`text-body no-underline`}
        data-testid={`${template.id}-available`}
      >
        <DocExplorerLeafContent
          template={template}
          Icon={NoteAddOutlined}
          title="Static Document"
          color="inherit"
        />
      </Link>
    );
  }

  if (docStatus === DocumentStatus.DRAFT) {
    return (
      <Link
        to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
          ":templateId",
          template.id
        )}
        className={`text-body no-underline`}
        data-testid={`${template.id}-draft`}
      >
        <DocExplorerLeafContent
          template={template}
          title="Draft"
          Icon={EditOutlined}
          color="warning"
        />
      </Link>
    );
  }

  if (docStatus === DocumentStatus.UNAPPROVED) {
    return (
      <Link
        to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
          ":templateId",
          template.id
        )}
        className={`text-body no-underline`}
        data-testid={`${template.id}-unapproved`}
      >
        <DocExplorerLeafContent
          template={template}
          title="Unapproved"
          Icon={HistoryEdu}
        />
      </Link>
    );
  }

  // Render completed documents
  return (
    <Link
      to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
        ":templateId",
        template.id
      )}
      className={`text-body no-underline`}
      data-testid={`${template.id}-live`}
    >
      <DocExplorerLeafContent
        template={template}
        title="Live"
        Icon={TaskOutlined}
        color="success"
      />
    </Link>
  );
};

export default DocExplorerLeaf;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { TaskContent } from "src/components/Roadmap/Task/TaskContent";
import { ROUTES, RoadmapCard, RoadmapStageId } from "../../config";

type Props = {
  cardConfig: { card: RoadmapCard; phase: RoadmapStageId };
  deviceId: string;
  open: boolean;
  handleCloseCardDialog: () => void;
};

export const TaskModal = (props: Props) => {
  const {
    cardConfig: { card: selectedCard },
    deviceId,
    open,
    handleCloseCardDialog,
  } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={handleCloseCardDialog}>
      <DialogTitle>{selectedCard.title}</DialogTitle>
      <DialogContent className="flex flex-col items-start gap-y-4">
        {/* Leave here to showcase ability to define custom content for a card */}
        {selectedCard.customContent && null}

        <div className="flex w-full flex-1 flex-col">
          <Typography variant="body1" className="mb-2 font-bold">
            Tasks
          </Typography>
          <div className="flex flex-col gap-y-2">
            <TaskContent {...props} />
          </div>
        </div>
        <Divider className="w-full" />
        <div>
          <Typography variant="body1" className="mb-2 font-bold">
            Description
          </Typography>
          {selectedCard.tasksDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: selectedCard.tasksDescription,
              }}
              className="whitespace-pre-wrap"
            />
          )}
        </div>
        {selectedCard.afterTasksDescription && (
          <Typography
            variant="body1"
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: selectedCard.afterTasksDescription,
            }}
          />
        )}
        {selectedCard.techfileDescription && (
          <Typography
            variant="body1"
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: selectedCard.techfileDescription,
            }}
          />
        )}
        {selectedCard.helpCenterArticleId && (
          <Link
            component={RouterLink}
            underline="hover"
            to={ROUTES.HELP_CENTER_ARTICLE.replace(
              ":articleId",
              selectedCard.helpCenterArticleId
            )}
          >
            Need more help? Read the detailed guide in the help center
          </Link>
        )}
      </DialogContent>
      <DialogActions className="flex justify-between">
        <Button onClick={handleCloseCardDialog}>Close</Button>
        {selectedCard.link && (
          <Button
            variant="contained"
            onClick={() =>
              selectedCard.link &&
              navigate(selectedCard.link.replace(":deviceId", deviceId))
            }
          >
            Open Technical File Builder
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

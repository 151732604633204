export enum ROUTES {
  CALLBACK = "/callback",
  DEVICE_MANAGEMENT = "/device-management",
  DEVICE_OVERVIEW = "/device/:deviceId",
  ERROR = "/error",
  FORM = "/form",
  HELP_CENTER = "/help-center",
  HELP_CENTER_ARTICLE = "/help-center/:articleId",
  QMS = "/device/:deviceId/documents",
  QMS_OPEN_DOC = "/device/:deviceId/documents/:templateId",
  SIGNUP = "/signup",
  TEMPLATE_ASSISTANT = "/device/:deviceId/template-assistant/:templateId/:docId",
  TEMPLATE_EDITOR = "/device/:deviceId/templates/:templateId",
  SUBSCRIPTION = "/subscription",
  CHECKOUT_SUCCESS = "/checkout/success",
  CHECKOUT_CANCEL = "/checkout/cancel",
  MANAGE_ORGANIZATION = "/organization/:orgId",
}

import { useAnswerHistory } from "@hooks";
import { ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import Handlebars from "handlebars";
import { useEffect, useMemo, useState } from "react";
import { isStepComplete } from "src/utils/step";
import {
  Device,
  Document,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "../../stores/models";
import {
  Answers,
  DocumentDataKey,
  StepValue,
  SuggestionState,
  TemplateElement,
} from "../../types";
import { ElementMapper } from "./ElementMapper";
import { HelpBox } from "./HelpBox";
import { Suggestion } from "./Suggestion";
import { findElementAnswer, getAnswer } from "./utils";

type Props = {
  templateId: TEMPLATE_TYPE;
  currentStep: number;
  steps: TemplateElement[];
  step: TemplateElement;
  index: number;
  answers: Answers;
  isGenerating: boolean;
  direction: "up" | "down";
  containerRef: React.RefObject<HTMLDivElement>;
  handleFinalStep: () => void;
  handleChange: (elementId: string, value: StepValue) => void;
  nextStep: (
    step: TemplateElement,
    documentVersion: DocumentVersion,
    answerFileId?: string
  ) => void;
  hasInlineSuggestion: boolean;
  suggestions: SuggestionState;
  device: Device;
  documentVersion: DocumentVersion;
  documents: Document[];
  handleApply: (stepId: DocumentDataKey, suggestion: string) => void;
  onRegenerateSuggestion: (
    device: Device,
    documents: Document[],
    documentVersion: DocumentVersion,
    step: TemplateElement
  ) => void;
};

export const Step = ({
  templateId,
  currentStep,
  answers,
  device,
  documents,
  documentVersion,
  handleApply,
  handleChange,
  handleFinalStep,
  hasInlineSuggestion,
  index,
  isGenerating,
  nextStep,
  onRegenerateSuggestion,
  step,
  steps,
  suggestions,
}: Props) => {
  const visible = currentStep === index + 1;

  const { element } = step;
  const dataKey = element.id as DocumentDataKey;

  const [forceRunTransformer, setForceRunTransformer] = useState(false);
  const [_, updateHistory, undo, redo] = useAnswerHistory({
    elementId: step.id,
  });

  const handleUndo = () => {
    const newAnswer = undo();
    if (newAnswer) {
      handleChange(element.id, { answer: newAnswer });
    }
  };

  const handleRedo = () => {
    const newAnswer = redo();
    if (newAnswer) {
      handleChange(element.id, { answer: newAnswer });
    }
  };

  const answer = useMemo(() => {
    return getAnswer(
      templateId,
      answers[step.id]?.answer,
      step,
      documentVersion,
      documents,
      forceRunTransformer
    );
  }, [answers[dataKey], visible, forceRunTransformer]);

  const currentAnswer = answers[dataKey]?.answer;
  const currentFileId = answers[dataKey]?.answerFileId;

  const versionAnswer = findElementAnswer({
    answers: documentVersion.answers,
    elementId: steps[currentStep - 1].id,
  });
  const fullAnswer = {
    ...versionAnswer,
    answer: currentAnswer === undefined ? versionAnswer?.answer : currentAnswer,
    answerFileId:
      currentFileId === undefined ? versionAnswer?.answerFileId : currentFileId,
  };

  const fileId = fullAnswer?.answerFileId;

  useEffect(() => {
    if (answers[dataKey]?.answer && answer !== answers[dataKey].answer) {
      handleChange(element.id, { answer: answers[dataKey].answer });
    }
    if (forceRunTransformer) {
      setForceRunTransformer(false);
    }
  }, [answers[dataKey]?.answer, answer]);

  const suggestionState = suggestions[step.id];
  if (!suggestionState) {
    throw new Error(
      "Error trying to access suggestion. A suggestion with the given id does not exist in the state."
    );
  }

  const handleReRunTransformer = () => {
    setForceRunTransformer(true);
  };

  const isFetchingSuggestion = suggestionState.loading;

  useEffect(() => {
    updateHistory(answer);
  }, [answer]);

  if (!!element.options.helperText) {
    // Extract all data keys and their answers from all the latest versions of the documents
    const dataKeys = documents.reduce(
      (acc, doc) => ({
        ...acc,
        ...doc.versions[0]?.answers.reduce(
          (answersAcc, answer) => ({
            ...answersAcc,
            [answer.element]: answer.answer,
          }),
          {}
        ),
      }),
      {}
    );

    const helperText = element.options.helperText
      .split("\n")
      .map((line) => line.trim())
      .join("\n");
    // Use Handlebars to replace variables in the helper text
    const template = Handlebars.compile(helperText);
    element.options.helperText = template(dataKeys);
  }

  if (!visible) {
    return null;
  }

  return (
    // replacing div with slider for now since it caused performance issues
    <div
      key={step.id}
      className={`${visible ? "w-full" : "hidden"} flex flex-1 flex-col gap-y-2`}
    >
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1">{element.options.label}</Typography>
        {(!!element.options.helperText || step.videoUrl) && (
          <HelpBox step={step} />
        )}
      </div>
      <div className="flex w-full flex-1 flex-row items-start gap-4">
        {!hasInlineSuggestion && step.prompt && (
          <Suggestion
            suggestions={suggestions}
            step={step}
            handleApply={handleApply}
            device={device}
            documentVersion={documentVersion}
            onRegenerateSuggestion={() =>
              onRegenerateSuggestion(device, documents, documentVersion, step)
            }
          />
        )}

        <div
          className={`flex ${
            hasInlineSuggestion ? "w-full" : "w-1/2"
          } h-full flex-1 flex-col gap-y-4`}
        >
          <ElementMapper
            step={step}
            suggestions={suggestions}
            onChange={handleChange}
            hasInlineSuggestion={hasInlineSuggestion}
            value={answer}
            onRegenerateSuggestion={() =>
              onRegenerateSuggestion(device, documents, documentVersion, step)
            }
            onReRunTransformer={handleReRunTransformer}
            onApplySuggestion={handleApply}
            onRedo={handleRedo}
            onUndo={handleUndo}
            answerFileId={fileId}
          />
          <div className="self-end">
            {currentStep !== steps.length && (
              <Button
                onClick={() =>
                  nextStep(step, documentVersion, fileId ?? undefined)
                }
                disabled={
                  !isStepComplete(step, fullAnswer) ||
                  currentStep === steps.length
                }
                variant="contained"
                endIcon={<ChevronRight />}
                //className="px-4 py-2 bg-blue-300 disabled:opacity-50"
              >
                Next
              </Button>
            )}
            {/* If last step, show generate button */}
            {currentStep === steps.length && (
              <LoadingButton
                variant="contained"
                loading={isGenerating}
                disabled={
                  !isStepComplete(step, fullAnswer) ||
                  isGenerating ||
                  isFetchingSuggestion
                }
                onClick={handleFinalStep}
              >
                Generate
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

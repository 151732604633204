import { theme } from "@config";
import { useGetUser, useGetUsersByOrg } from "@hooks";
import {
  AddCircleOutlined,
  ApartmentOutlined,
  Edit,
  GroupsOutlined,
} from "@mui/icons-material";
import { Card, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import GenericButton from "../components/GenericButton/GenericButton";
import useInviteUser from "../components/ManageOrg/hooks/useInviteUser";
import useUpdateOrgName from "../components/ManageOrg/hooks/useUpdateOrgName";
import useUpdateUserData from "../components/ManageOrg/hooks/useUpdateUserData";
import Modals from "../components/ManageOrg/Modals";
import BasicTable from "../components/UsersTable/UsersTable";
import { Loading } from "./Loading";

export const Organization = () => {
  const { orgId = "" } = useParams();
  const { data: user } = useGetUser();

  const { data: organizationUsers } = useGetUsersByOrg({
    orgId,
  });

  const { updateOrgNameResponse } = useUpdateOrgName({
    orgId,
    oldName: user?.organization.name ?? "",
  });

  const { inviteUserResponse } = useInviteUser({ orgId });

  const { updateUserDataResponse } = useUpdateUserData({
    orgId,
  });

  if (!organizationUsers || !user) return <Loading />;

  return (
    <div className="flex flex-col self-center w-full gap-6">
      <Typography variant="h1" className="mt-2">
        Manage Organization
      </Typography>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <ApartmentOutlined />
          <Typography variant="body1" data-testid="name-subheader">
            Name
          </Typography>
        </div>
        <Card
          className="flex items-center gap-2"
          sx={{
            border: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "1rem",
            boxShadow: "none",
            lineHeight: 2,
            padding: "9px 10px",
            backgroundColor: "transparent",
          }}
        >
          {user.organization.name}
          <Edit
            fontSize="small"
            style={{ color: theme.palette.grey[500] }}
            cursor="pointer"
            onClick={() => {
              updateOrgNameResponse.setOpenChangeOrgNameModal(true);
            }}
          />
        </Card>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <GroupsOutlined />
          <Typography variant="body1" data-testid="members-subheader">
            Members
          </Typography>
          <GenericButton
            id="basic-button"
            variant="contained"
            onClick={() => {
              inviteUserResponse.setOpenInviteUserModal(true);
            }}
            tooltipContent="Add Member"
            color="primary"
          >
            <AddCircleOutlined fontSize="small" color="primary" />
          </GenericButton>
        </div>
        <BasicTable
          rows={organizationUsers}
          onClick={(user) => {
            updateUserDataResponse.setSelectedUser(user);
            updateUserDataResponse.setOpenUpdateUserModal(true);
          }}
          disableCellSelection
        />
      </div>
      <Modals
        useUpdateOrgName={updateOrgNameResponse}
        useInviteUser={inviteUserResponse}
        useUpdateUserData={updateUserDataResponse}
      />
    </div>
  );
};

import { ParsedFileUploadElement, StepValue } from "@types";
import UploadContainer from "src/components/Form/Elements/UploadContainer";

type Props = {
  element: ParsedFileUploadElement;
  value: string;
  onChange: (id: string, value: StepValue) => void;
};

const ParsedFileUpload = ({ element, value, onChange }: Props) => {
  const handleChange = async (file: File) => {
    const { result } = await element.options.onUpload(file);
    onChange(element.id, { answer: result });
  };

  return (
    <UploadContainer
      onChange={handleChange}
      element={element}
      allowedFileTypes={element.options.allowedFileTypes}
      value={value}
    />
  );
};

export default ParsedFileUpload;

import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import FileUpload from "src/components/Form/Elements/FileUpload";
import ParsedFileUpload from "src/components/Form/Elements/ParsedFileUpload";
import Select from "src/components/Form/Elements/Select";
import TextElement from "src/components/Form/Elements/TextField";
import { useSnackbar } from "..";
import { TEMPLATE_TYPE } from "../../stores/models";
import { DocumentDataKey, StepValue, TemplateElement } from "../../types";
import { Table } from "./Elements/Table";
import { Toolbar } from "./Toolbar";
import { handleCSVUpload } from "./utils";

type Props = {
  value: string;
  hasInlineSuggestion?: boolean;
  disabled?: boolean;
  onChange: (id: string, value: StepValue) => void;
  onRegenerateSuggestion: () => void;
  onReRunTransformer: () => void;
  onApplySuggestion?: (id: DocumentDataKey, value: string) => void;
  step: TemplateElement;
  suggestions: {
    [id: string]: {
      completed: boolean;
      value: string;
      saved: boolean;
      applied: boolean;
      loading: boolean;
    };
  };
  onUndo: () => void;
  onRedo: () => void;
  answerFileId: string | undefined | null;
};

export const ElementMapper: React.FC<Props> = ({
  disabled,
  hasInlineSuggestion = false,
  onChange,
  onRegenerateSuggestion,
  onReRunTransformer,
  onUndo,
  onRedo,
  step,
  suggestions,
  value,
  answerFileId,
}) => {
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { templateId } = useParams<{
    templateId: TEMPLATE_TYPE;
  }>();

  if (!templateId) {
    throw new Error("No template id specified");
  }
  const { element } = step;

  const hasTransformer = element.transformerConfig?.type === "default";
  const isFetchingSuggestion = suggestions[element.id].loading;

  const renderElement = () => {
    switch (element.type) {
      case "fileUpload":
        return (
          <FileUpload
            element={element}
            answerFileId={answerFileId}
            onChange={onChange}
          />
        );

      case "parsedFileUploadElement":
        return (
          <ParsedFileUpload
            element={element}
            value={value}
            onChange={onChange}
          />
        );

      case "table":
        return (
          <Table
            key={element.id}
            ref={tableRef}
            element={element}
            value={value}
            onChange={onChange}
            isFetchingSuggestion={isFetchingSuggestion}
          />
        );

      case "textField":
        return (
          <TextElement
            disabled={disabled}
            onChange={onChange}
            element={element}
            value={value}
          />
        );

      case "select":
        return (
          <Select
            disabled={disabled}
            onChange={onChange}
            value={value}
            element={element}
          />
        );

      default:
        return null;
    }
  };

  const importAnswer = async (file: File) => {
    try {
      hideSnackbar();
      const importedAnswer = await handleCSVUpload(file, templateId, step.id);
      onChange(step.id, { answer: importedAnswer });
    } catch (error: any) {
      showSnackbar(error.message || "Please upload a valid CSV file.");
    }
  };

  const handleCopyTable = useCallback(() => {
    if (element.type === "table" && tableRef.current) {
      tableRef.current.copyTable();
    }
  }, [element.type]);

  const tableRef = React.useRef<{ copyTable: () => void } | null>(null);

  return (
    <div className="flex flex-col">
      <Toolbar
        hasCSVUpload={element.type === "table"}
        hasInlineSuggestion={hasInlineSuggestion}
        hasTransformer={hasTransformer}
        onRegenerateSuggestion={onRegenerateSuggestion}
        onReRunTransformer={onReRunTransformer}
        onCSVUpload={(file: File) => importAnswer(file)}
        onUndo={element.type === "table" ? onUndo : undefined}
        onRedo={element.type === "table" ? onRedo : undefined}
        onCopyTable={element.type === "table" ? handleCopyTable : undefined}
      />
      {renderElement()}
    </div>
  );
};

import { ComponentConfigurationType, ComponentType } from "@models";
import { Button, TextField } from "@mui/material";
import { ComponentItem } from "./ComponentItem";
import { SoftwareComponentConfigurationProps } from "./types";
import { YesNo } from "./YesNo";

export const SoftwareComponentConfiguration = ({
  newDevice,
  toggleProductComponentCollapse,
  updateProductComponent,
  updateComponentConfigurationValue,
  getComponentConfigurationValue,
  removeProductComponent,
  addProductComponent,
}: SoftwareComponentConfigurationProps) => {
  return (
    <div>
      <div className="flex flex-col gap-y-3 mb-3">
        {newDevice.components
          .filter((component) => component.type === ComponentType.SOFTWARE)
          .map((component, index) => {
            const { collapsed, name, description } = component;
            return (
              <ComponentItem
                key={component.id}
                component={component}
                collapsed={collapsed}
                toggleProductComponentCollapse={toggleProductComponentCollapse}
                index={index}
              >
                <div className="flex flex-col gap-y-2">
                  <TextField
                    label="Name"
                    required
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      updateProductComponent({
                        ...component,
                        name: event.target.value,
                      })
                    }
                    value={name}
                  />
                  <TextField
                    label="Description"
                    required
                    fullWidth
                    multiline
                    minRows={3}
                    value={description}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      updateProductComponent({
                        ...component,
                        description: event.target.value,
                      })
                    }
                  />
                  <YesNo
                    question="Does the software have AI?"
                    required
                    value={getComponentConfigurationValue(
                      component,
                      ComponentConfigurationType.HAS_AI
                    )}
                    onChange={(value) => {
                      console.log(value);
                      updateComponentConfigurationValue(
                        component,
                        ComponentConfigurationType.HAS_AI,
                        value
                      );
                    }}
                  />
                  <div className="flex justify-end">
                    <Button
                      className="justify-self-end"
                      variant="outlined"
                      color="error"
                      onClick={() => removeProductComponent(component)}
                    >
                      Delete Component
                    </Button>
                  </div>
                </div>
              </ComponentItem>
            );
          })}
      </div>
      <Button
        variant="text"
        // color="success"
        onClick={() => addProductComponent(ComponentType.SOFTWARE)}
      >
        Add another software component
      </Button>
    </div>
  );
};

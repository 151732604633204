import {
  ComponentConfigurationType,
  ComponentType,
  SubComponentConfigurationType,
  SubComponentType,
} from "@models";
import { Button, TextField } from "@mui/material";
import { ComponentState, SubComponentState } from "@types";
import { ComponentItem } from "./ComponentItem";
import { HardwareComponentConfigurationProps } from "./types";
import { YesNo } from "./YesNo";

export const HardwareComponentConfiguration = ({
  newDevice,
  toggleProductComponentCollapse,
  updateProductComponent,
  updateComponentConfigurationValue,
  getComponentConfigurationValue,
  addSubComponent,
  removeAllSubComponentsByType,
  updateSubComponent,
  updateSubComponentConfigurationValue,
  getSubComponentConfigurationValue,
  removeProductComponent,
  addProductComponent,
}: HardwareComponentConfigurationProps) => {
  const hasFirmware = (
    component: ComponentState
  ): SubComponentState | undefined => {
    return component.subComponents.find(
      (subComponent) => subComponent.type === SubComponentType.FIRMWARE
    );
  };

  const hasHardwareSoftware = (
    component: ComponentState
  ): SubComponentState | undefined => {
    return component.subComponents.find(
      (subComponent) => subComponent.type === SubComponentType.SOFTWARE
    );
  };

  const hasElectrical = (
    component: ComponentState
  ): SubComponentState | undefined => {
    return component.subComponents.find(
      (subComponent) => subComponent.type === SubComponentType.ELECTRICAL
    );
  };

  const hasMechanical = (
    component: ComponentState
  ): SubComponentState | undefined => {
    return component.subComponents.find(
      (subComponent) => subComponent.type === SubComponentType.MECHANICAL
    );
  };

  return (
    <div>
      <div className="flex flex-col gap-y-3 mb-3">
        {newDevice.components
          .filter((component) => component.type === ComponentType.HARDWARE)
          .map((component, index) => {
            const { collapsed, name, description } = component;
            const mechanical = hasMechanical(component);
            const electrical = hasElectrical(component);
            const firmware = hasFirmware(component);
            const software = hasHardwareSoftware(component);
            return (
              <ComponentItem
                key={component.id}
                component={component}
                collapsed={collapsed}
                toggleProductComponentCollapse={toggleProductComponentCollapse}
                index={index}
              >
                <div className="flex flex-col gap-y-2">
                  <TextField
                    label="Name"
                    id="component-name"
                    required
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      updateProductComponent({
                        ...component,
                        name: event.target.value,
                      })
                    }
                    value={name}
                  />
                  <TextField
                    label="Description"
                    id="component-description"
                    required
                    fullWidth
                    multiline
                    minRows={3}
                    value={description}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      updateProductComponent({
                        ...component,
                        description: event.target.value,
                      })
                    }
                  />
                  <YesNo
                    question="Is it intended to be sterilized?"
                    required
                    value={getComponentConfigurationValue(
                      component,
                      ComponentConfigurationType.IS_STERILIZATION_INTENDED
                    )}
                    onChange={(value) =>
                      updateComponentConfigurationValue(
                        component,
                        ComponentConfigurationType.IS_STERILIZATION_INTENDED,
                        value
                      )
                    }
                  />
                  <YesNo
                    question="Does it come in contact with the human body?"
                    required
                    value={getComponentConfigurationValue(
                      component,
                      ComponentConfigurationType.HAS_HUMAN_CONTACT
                    )}
                    onChange={(value) =>
                      updateComponentConfigurationValue(
                        component,
                        ComponentConfigurationType.HAS_HUMAN_CONTACT,
                        value
                      )
                    }
                  />
                  <YesNo
                    question="Is it an implantable?"
                    required
                    value={getComponentConfigurationValue(
                      component,
                      ComponentConfigurationType.IS_IMPLANTABLE
                    )}
                    onChange={(value) =>
                      updateComponentConfigurationValue(
                        component,
                        ComponentConfigurationType.IS_IMPLANTABLE,
                        value
                      )
                    }
                  />
                  {/* Mechanical Parts */}
                  <YesNo
                    question="Does it contain mechanical parts?"
                    required
                    value={!!mechanical}
                    onChange={(value) =>
                      value === "true"
                        ? addSubComponent(
                            SubComponentType.MECHANICAL,
                            component,
                            "Mechanical Parts"
                          )
                        : removeAllSubComponentsByType(
                            component,
                            SubComponentType.MECHANICAL
                          )
                    }
                  />
                  {!!mechanical && (
                    <div className="flex flex-col border-solid border-0 border-l border-gray-300 -mt-2 ml-2 pl-3">
                      <h4>Mechanical Parts</h4>
                      <TextField
                        label="Description"
                        required
                        fullWidth
                        multiline
                        minRows={3}
                        value={mechanical.description}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          updateSubComponent(component, {
                            ...mechanical,
                            description: event.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  {/* Electrical Parts */}
                  <YesNo
                    question="Does it contain electrical parts?"
                    required
                    value={!!electrical}
                    onChange={(value) =>
                      value === "true"
                        ? addSubComponent(
                            SubComponentType.ELECTRICAL,
                            component,
                            "Electrical Parts"
                          )
                        : removeAllSubComponentsByType(
                            component,
                            SubComponentType.ELECTRICAL
                          )
                    }
                  />
                  {!!electrical && (
                    <div className="flex flex-col border-solid border-0 border-l border-gray-300 ml-2 pl-3">
                      <h4>Electrical Parts</h4>
                      <div className="flex flex-col gap-y-2">
                        <TextField
                          label="Description"
                          required
                          fullWidth
                          multiline
                          minRows={3}
                          value={electrical.description}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            updateSubComponent(component, {
                              ...electrical,

                              description: event.target.value,
                            })
                          }
                        />
                        <YesNo
                          question="Is the electrical part an implantable?"
                          required
                          value={getSubComponentConfigurationValue(
                            electrical,
                            SubComponentConfigurationType.IS_IMPLANTABLE
                          )}
                          onChange={(value) =>
                            updateSubComponentConfigurationValue(
                              component,
                              electrical,
                              SubComponentConfigurationType.IS_IMPLANTABLE,
                              value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  {/* Firmware */}
                  <YesNo
                    question="Does it contain firmware?"
                    required
                    value={!!firmware}
                    onChange={(value) =>
                      value === "true"
                        ? addSubComponent(
                            SubComponentType.FIRMWARE,
                            component,
                            "Firmware"
                          )
                        : removeAllSubComponentsByType(
                            component,
                            SubComponentType.FIRMWARE
                          )
                    }
                  />
                  {!!firmware && (
                    <div className="flex flex-col border-solid border-0 border-l border-gray-300 ml-2 pl-3">
                      <h4>Firmware</h4>
                      <TextField
                        label="Description"
                        required
                        fullWidth
                        multiline
                        minRows={3}
                        value={firmware.description}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          updateSubComponent(component, {
                            ...firmware,
                            description: event.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                  {/* Software */}
                  <YesNo
                    question="Does it contain software?"
                    required
                    value={!!software}
                    onChange={(value) =>
                      value === "true"
                        ? addSubComponent(
                            SubComponentType.SOFTWARE,
                            component,
                            "Software"
                          )
                        : removeAllSubComponentsByType(
                            component,
                            SubComponentType.SOFTWARE
                          )
                    }
                  />
                  {!!software && (
                    <div className="flex flex-col border-solid border-0 border-l border-gray-300 ml-2 pl-3">
                      <h4>Software</h4>
                      <TextField
                        label="Description"
                        fullWidth
                        multiline
                        minRows={3}
                        required
                        value={software.description}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          updateSubComponent(component, {
                            ...software,
                            description: event.target.value,
                          })
                        }
                      />
                      <YesNo
                        question="Does the software have AI?"
                        required
                        value={getComponentConfigurationValue(
                          component,
                          ComponentConfigurationType.HAS_AI
                        )}
                        onChange={(value) => {
                          updateComponentConfigurationValue(
                            component,
                            ComponentConfigurationType.HAS_AI,
                            value
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="flex justify-end">
                  <Button
                    className="justify-self-end"
                    variant="outlined"
                    color="error"
                    onClick={() => removeProductComponent(component)}
                  >
                    Delete Component
                  </Button>
                </div>
              </ComponentItem>
            );
          })}
      </div>
      <Button
        variant="text"
        onClick={() => addProductComponent(ComponentType.HARDWARE)}
      >
        Add another hardware component
      </Button>
    </div>
  );
};

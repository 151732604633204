import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import GenericButton from "../GenericButton/GenericButton";

const GenericModal = ({
  children,
  isDisabled,
  handleCancel,
  handleSave,
  title,
  description,
  isOpen = false,
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
  handleSave: () => void;
  handleCancel?: () => void;
  title: string;
  description?: string;
  isOpen: boolean;
}) => {
  return (
    <Dialog
      className="rounded"
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "100%",
          minWidth: "40%",
        },
      }}
    >
      <div
        className={`transition-transform origin-center duration-500 
       ${isOpen ? "scale-100" : "scale-0"}`}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogContent className="flex flex-col gap-2.5 pt-0">
          {children}
        </DialogContent>
        <DialogActions>
          {handleCancel && (
            <GenericButton
              id="basic-button"
              onClick={handleCancel}
              text="Cancel"
              variant="outlined"
              color="inherit"
            />
          )}
          <GenericButton
            isDisabled={isDisabled}
            id="basic-button"
            variant="contained"
            onClick={handleSave}
            text="Save"
            color="primary"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default GenericModal;

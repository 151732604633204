import { array, date, InferType, mixed, object, string } from "yup";

const configurationItemSchema = object({
  type: string().required(),
  value: mixed<string | boolean>().required(),
});

export interface ConfigurationItem
  extends InferType<typeof configurationItemSchema> {}

export enum ComponentType {
  HARDWARE = "HARDWARE",
  SOFTWARE = "SOFTWARE",
}

export enum SubComponentType {
  MECHANICAL = "MECHANICAL",
  ELECTRICAL = "ELECTRICAL",
  FIRMWARE = "FIRMWARE",
  SOFTWARE = "SOFTWARE",
}

export enum ComponentConfigurationType {
  HAS_AI = "hasAI",
  IS_IMPLANTABLE = "isImplantable",
  IS_STERILIZATION_INTENDED = "isSterilizationIntended",
  HAS_HUMAN_CONTACT = "hasHumanContact",
}

export enum SubComponentConfigurationType {
  IS_IMPLANTABLE = "isImplantable",
  HAS_AI = "hasAI",
}

const subComponentSchema = object({
  id: string().uuid().required(),
  name: string().required(),
  description: string().required(),
  type: mixed<SubComponentType>()
    .oneOf(Object.values(SubComponentType))
    .required(),
  configuration: array(configurationItemSchema).required(),
});

export interface SubComponent extends InferType<typeof subComponentSchema> {}

const componentSchema = object({
  id: string().uuid().required(),
  type: mixed<ComponentType>().oneOf(Object.values(ComponentType)).required(),
  configuration: array(configurationItemSchema).required(),
  subComponents: array(subComponentSchema).required(),
  name: string().required(),
  description: string().required(),
});

export interface Component extends InferType<typeof componentSchema> {}

export const deviceSchema = object({
  id: string().required(),
  name: string().required(),
  description: string().required(),
  components: array(componentSchema).required(),
  createdBy: string().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
});

export interface Device extends InferType<typeof deviceSchema> {}

import { useUpdateUserMutation } from "@hooks";
import { BaseUser } from "@models";
import { useState } from "react";
import { useSnackbar } from "src/components/Snackbar";

export const emptyUser = { firstName: "", lastName: "" };

export type ChangeUser = {
  firstName: string;
  lastName: string;
};

export type UpdateUserDataProps = {
  updateUserDataResponse: UpdateUserDataResponse;
};

export type UpdateUserDataResponse = {
  openUpdateUserModal: boolean;
  setOpenUpdateUserModal: (open: boolean) => void;
  userToUpdate: ChangeUser;
  setUserToUpdate: (userToUpdate: ChangeUser) => void;
  areAllUpdateUserInputsFilled: boolean;
  handleUpdateUser: ({ userId }: { userId: string }) => void;
  handleCloseUpdateUserModal: () => void;
  selectedUser: BaseUser | undefined;
  setSelectedUser: (selectedUser: BaseUser | undefined) => void;
};

export default function useUpdateUserData({
  orgId,
}: {
  orgId: string;
}): UpdateUserDataProps {
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState<ChangeUser>(emptyUser);
  const [selectedUser, setSelectedUser] = useState<BaseUser | undefined>(
    undefined
  );
  const { showSnackbar } = useSnackbar();

  const patchUserMutation = useUpdateUserMutation(orgId);

  const handleCloseUpdateUserModal = () => {
    setOpenUpdateUserModal(false);
  };

  const handleUpdateUser = async ({ userId }: { userId: string }) => {
    const waitMutation = await patchUserMutation.mutateAsync({
      id: userId,
      user: userToUpdate,
    });

    const isMutationSuccessful = waitMutation.status === 200;

    !isMutationSuccessful && showSnackbar("Failed to update user");

    setUserToUpdate(emptyUser);
    setOpenUpdateUserModal(false);
  };

  const areAllUpdateUserInputsFilled =
    !!userToUpdate?.firstName && !!userToUpdate?.lastName;

  return {
    updateUserDataResponse: {
      openUpdateUserModal,
      setOpenUpdateUserModal,
      userToUpdate,
      setUserToUpdate,
      areAllUpdateUserInputsFilled,
      handleUpdateUser,
      handleCloseUpdateUserModal,
      selectedUser,
      setSelectedUser,
    },
  };
}

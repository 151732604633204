import { ComponentType } from "@models";
import { UnfoldLessRounded, UnfoldMoreRounded } from "@mui/icons-material";
import { Collapse, Typography } from "@mui/material";
import { ComponentState } from "@types";

export const ComponentItem = ({
  component,
  collapsed,
  toggleProductComponentCollapse,
  index,
  children,
}: {
  component: ComponentState;
  collapsed: boolean;
  toggleProductComponentCollapse: (component: ComponentState) => void;
  index: number;
  children: React.ReactNode;
}) => {
  const { name, type } = component;
  const defaultName = `${type === ComponentType.HARDWARE ? "Hardware" : "Software"} Component #${index + 1}`;
  return (
    <div
      id={`component-${component.id}`}
      className={`flex flex-col border border-solid border-gray-200 p-4 rounded-md pt-2 ${collapsed ? "py-2" : "gap-y-1"}`}
      onClick={() => collapsed && toggleProductComponentCollapse(component)}
    >
      <div
        className="flex justify-between items-center hover:underline cursor-pointer py-2"
        onClick={() => toggleProductComponentCollapse(component)}
      >
        <Typography>{name || defaultName}</Typography>
        {collapsed && <UnfoldMoreRounded />}
        {!collapsed && <UnfoldLessRounded />}
      </div>

      <Collapse in={!collapsed}>{children}</Collapse>
    </div>
  );
};

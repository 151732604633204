import { Chip, Typography } from "@mui/material";
import { docStatusDictionary } from "@utils";
import { DocStatus, DocumentStatus } from "../../../../../types";

const SelectableOption = ({
  label,
  type = DocumentStatus.ARCHIVED,
  showStatus = false,
  disabled = false,
}: {
  label: string;
  type?: DocStatus;
  showStatus?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className="flex items-center gap-2 text-sm">
      {showStatus && (
        <Chip
          label={docStatusDictionary[type].label}
          variant="filled"
          color={disabled ? "default" : docStatusDictionary[type].color}
          size="small"
        />
      )}
      <Typography variant="body1">{label}</Typography>
    </div>
  );
};

export default SelectableOption;

import {
  CheckRounded,
  CloudUploadOutlined,
  ErrorOutline,
} from "@mui/icons-material";
import { Alert, Button, Skeleton } from "@mui/material";
import { FileUploadElement, ParsedFileUploadElement } from "@types";
import { parseMarkdownTable } from "@utils";
import { useEffect, useState } from "react";

type Props = {
  element: ParsedFileUploadElement | FileUploadElement;
  value?: string;
  onChange: (file: File) => void;
  allowedFileTypes: string | undefined;
  imgSrc?: string;
  isLoading?: boolean;
};

const UploadContainer = ({
  element,
  value,
  onChange,
  allowedFileTypes,
  imgSrc,
  isLoading,
}: Props) => {
  const [preview, setPreview] = useState("");
  const [uploadStatus, setUploadStatus] = useState("idle");

  const handleFileChange = async (
    file: File | undefined,
    custom: (file: File) => void
  ) => {
    setUploadStatus("loading");
    if (!file) {
      setUploadStatus("failed");
      return;
    }
    try {
      await custom(file);
      setUploadStatus("completed");
    } catch (error) {
      setUploadStatus("failed");
      return;
    }
  };

  useEffect(() => {
    if (value) {
      parsePreview(value);
    }
  }, [value]);

  const parsePreview = (text: string) => {
    let values = [];
    try {
      values = JSON.parse(text);
    } catch (error) {
      values = parseMarkdownTable(text);
    }
    setPreview(values.length + " publications uploaded.");
  };

  return (
    <div key={element.id} className="flex flex-col gap-y-3">
      <Button
        component="label"
        role={undefined}
        variant="outlined"
        tabIndex={-1}
        startIcon={<CloudUploadOutlined />}
      >
        Upload file
        <input
          type="file"
          className="hidden"
          accept={allowedFileTypes || undefined}
          onChange={(e) => {
            handleFileChange(e.target.files?.[0], onChange);
          }}
        />
      </Button>

      {imgSrc !== undefined &&
        (!isLoading ? (
          <div className="flex justify-center w-full">
            <img
              data-testid="uploaded-image"
              className="h-60 w-auto"
              src={imgSrc}
            />
          </div>
        ) : (
          <Skeleton className="h-60 w-auto" />
        ))}

      {uploadStatus !== "failed" &&
        (uploadStatus === "completed" || value) &&
        !imgSrc && (
          <Alert icon={<CheckRounded fontSize="inherit" />} severity="success">
            {preview}
          </Alert>
        )}

      {uploadStatus === "failed" && (
        <Alert icon={<ErrorOutline fontSize="inherit" />} severity="error">
          Failed to process the uploaded file.
        </Alert>
      )}
    </div>
  );
};

export default UploadContainer;

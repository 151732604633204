import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export const YesNo = ({
  value,
  onChange,
  question,
  required,
}: {
  question: string;
  value: boolean | string | undefined;
  onChange: (value: "true" | "false") => void;
  required?: boolean;
}) => {
  if (typeof value === "string") {
    switch (value) {
      case "true":
        value = true;
        break;
      default:
        value = false;
    }
  } else if (typeof value === "undefined") {
    value = false;
  }
  return (
    <FormControl required={required}>
      <span>
        {question} {required && "*"}
      </span>
      <RadioGroup
        row
        value={value}
        onChange={(event) => onChange(event.target.value as "true" | "false")}
      >
        <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
        <FormControlLabel value={"false"} control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

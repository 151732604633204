import { MenuItem, Typography } from "@mui/material";

import React from "react";
import { Link } from "react-router-dom";

export const UserCard = ({
  text,
  icon,
  to,
  onClick,
  external,
}: {
  text: string;
  icon: React.ReactNode;
  to?: string;
  onClick?: () => void;
  isActive?: boolean;
  external?: boolean;
}) => {
  return (
    <MenuItem
      {...(to ? { to, component: Link } : {})}
      {...(onClick ? { onClick } : {})}
      {...(external ? { target: "_blank", rel: "noopener noreferrer" } : {})}
      sx={{
        "&:hover": {
          bgcolor: "rgba(0, 0, 0, 0.04)",
        },
      }}
      className="gap-3"
    >
      {icon}
      <Typography variant="body1" className="text-wrap">
        {text}
      </Typography>
    </MenuItem>
  );
};

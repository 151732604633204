import { ROUTES } from "@config";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Link } from "react-router-dom";

export const FreePlanLimitModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Device limit reached</DialogTitle>
      <DialogContent className="flex flex-col gap-y-4">
        <Alert severity="warning">
          The free plan allows you to create only one device. Please upgrade to
          a paid plan to create additional devices.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          to={ROUTES.SUBSCRIPTION}
          variant="contained"
          color="primary"
        >
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import {
  Badge,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip,
} from "@mui/material";
import { ElementType } from "react";

type Btn = {
  hasOutstandingAction?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  onClickEvent?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  tooltipContent?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  size?: "small" | "medium" | "large";
  children?: React.ReactNode;
  component?: ElementType;
  showBadge?: boolean;
  badgeContent?: string;
  ariadescribedby?: string;
  ariacontrols?: string;
  ariaexpanded?: boolean;
  ariahaspopup?: "menu" | "listbox" | "dialog" | "grid" | "tree" | boolean;
  id?: string;
  openTooltip?: boolean;
  hideTooltip?: boolean;
  isIconButton?: boolean;
};

const GenericWithTooltip = (props: Btn) => {
  return (
    <Tooltip
      title={props.tooltipContent}
      placement={props.tooltipPlacement}
      {...(props.openTooltip && { open: props.openTooltip })}
    >
      <div>
        <ButtonAlone {...props} />
      </div>
    </Tooltip>
  );
};
export const IconButton = (props: Btn) => (
  <MuiIconButton
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
      if (typeof props.onClick === "function") {
        props.onClick();
      } else if (props.onClickEvent) {
        props.onClickEvent(e);
      }
    }}
    color={props.color}
    size={props.size}
    aria-describedby={props.ariadescribedby}
    disabled={props.isDisabled}
    aria-controls={props.ariacontrols}
    aria-expanded={props.ariaexpanded}
    aria-haspopup={props.ariahaspopup}
    id={props.id}
    className={`${props.isDisabled ? "pointer-events-none" : ""}`}
  >
    <Badge
      color="error"
      invisible={props.showBadge}
      badgeContent={props.badgeContent}
    >
      {props.children}
    </Badge>
  </MuiIconButton>
);

export const ButtonAlone = (props: Btn) => {
  return !props.text ? (
    <IconButton
      showBadge={props.showBadge}
      badgeContent={props.badgeContent}
      onClick={props.onClick}
      size={props.size || "small"}
      color={props.color}
      ariadescribedby={props.ariadescribedby}
      isDisabled={props.isDisabled}
      ariacontrols={props.ariacontrols}
      ariaexpanded={props.ariaexpanded}
      ariahaspopup={props.ariahaspopup}
      id={props.id}
      onClickEvent={props.onClickEvent}
    >
      {props.children}
    </IconButton>
  ) : (
    <>
      <MuiButton
        onClick={props.onClick}
        size={props.size || "medium"}
        variant={props.variant}
        color={props.color}
        disabled={props.isDisabled}
        endIcon={props.endIcon}
        startIcon={props.startIcon}
        component={props.component || "button"}
        className={`${props.isDisabled ? "pointer-events-none" : ""}`}
      >
        {props.text}
        {props.children}
      </MuiButton>
      {props.hasOutstandingAction && (
        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-800 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-700"></span>
        </span>
      )}
    </>
  );
};

const defaultValues = {
  hasOutstandingAction: false,
  isDisabled: false,
  tooltipContent: "",
  tooltipPlacement: "bottom",
  color: "inherit",
  variant: "outlined",
} as const;

export const Button = (props: Btn) => {
  return (
    <div className="relative inline-flex">
      {props.tooltipContent && !props.hideTooltip ? (
        <GenericWithTooltip {...defaultValues} {...props} />
      ) : (
        <ButtonAlone {...defaultValues} {...props} />
      )}
    </div>
  );
};

export default Button;

import { ComponentType, Device, SubComponentType } from "@models";

export const hasSoftware = (device: Device): boolean => {
  return (
    device.components.some(
      (component) => component.type === ComponentType.SOFTWARE
    ) ||
    device.components.some((c) =>
      c.subComponents.some((sc) => sc.type === SubComponentType.SOFTWARE)
    )
  );
};

export const hasHardware = (device: Device): boolean => {
  return device.components.some(
    (component) => component.type === ComponentType.HARDWARE
  );
};

export const isSoftwareOnly = (device: Device): boolean => {
  return device.components.every(
    (component) => component.type === ComponentType.SOFTWARE
  );
};

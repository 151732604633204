import * as yup from "yup";
import { array, boolean, date, number, object, string } from "yup";
import { DocumentDataKey } from "../../types";
import { BaseUserSchema } from "./user";

export const DocumentAnswerSchema = object({
  id: string().required(),
  answer: string().nullable(),
  element: string<DocumentDataKey>().defined().required(),
  documentVersionId: string().required(),
  createdBy: string().required(),
  createdAt: date().default(() => new Date()),
  updatedAt: date(),
  answerFileId: string().uuid().nullable(),
});

export interface DocumentAnswer
  extends yup.InferType<typeof DocumentAnswerSchema> {}

export const DocumentAnswerSuggestionSchema = object().shape({
  id: string().required(),
  suggestion: string().required(),
  element: string().required(),
  documentVersionId: string().required(),
  createdBy: string().required(),
  createdAt: date().default(() => new Date()),
  updatedAt: date(),
});

export interface DocumentAnswerSuggestion
  extends yup.InferType<typeof DocumentAnswerSuggestionSchema> {}

export enum DocumentVersionApproverStatus {
  USER_INVITE_SENT = "USER_INVITE_SENT",
  AWAITING_SIGNATURE = "AWAITING_SIGNATURE",
  APPROVED = "APPROVED",
}

export const DocumentVersionApproverSchema = object({
  id: string().uuid().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
  documentVersionId: string().uuid().required(),
  userId: string().uuid(),
  approvalStatus: yup
    .mixed<DocumentVersionApproverStatus>()
    .oneOf(Object.values(DocumentVersionApproverStatus))
    .required(),
  user: BaseUserSchema.required(),
});

export interface DocumentVersionApprover
  extends yup.InferType<typeof DocumentVersionApproverSchema> {}

export const BaseDocumentVersionSchema = object({
  id: string().required(),
  documentId: string().required(),
  readyForApproval: boolean().required(),
  documentVersionApprover: array()
    .of(DocumentVersionApproverSchema)
    .default([]),
  revision: number().nullable(),
  createdBy: string().required(),
  createdAt: date().default(() => new Date()),
  updatedAt: date(),
});

export const FileSchema = object({
  id: string().required(),
  filename: string().required(),
  mimeType: string().required(),
  organizationId: string().required(),
  createdAt: date().required(),
  createdBy: string().required(),
  path: string().required(),
  encoding: string().required(),
});

export const DocumentVersionSchema = object({
  snapshot: string().nullable(),
  revisionSummary: string().default(""),
  answers: array().of(DocumentAnswerSchema).default([]),
  suggestions: array().of(DocumentAnswerSuggestionSchema).default([]),
  fileId: string().uuid().nullable(),
}).concat(BaseDocumentVersionSchema);

export interface BaseDocumentVersion
  extends yup.InferType<typeof BaseDocumentVersionSchema> {}

export interface DocumentVersion
  extends yup.InferType<typeof DocumentVersionSchema> {}

export const BaseDocumentSchema = object({
  id: string().uuid().required(),
  name: string().required(),
  deviceId: string().uuid().required(),
  createdBy: string().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
});

export const DocumentSchema = object({
  versions: array().of(DocumentVersionSchema).default([]),
}).concat(BaseDocumentSchema);

export const ReducedDocumentSchema = object({
  versions: array().of(BaseDocumentVersionSchema).default([]),
}).concat(BaseDocumentSchema);

export interface Document extends yup.InferType<typeof DocumentSchema> {}
export interface ReducedDocument
  extends yup.InferType<typeof ReducedDocumentSchema> {}

export const UserTodosSchema = object({
  documentsTasks: object({
    toApprove: array().of(ReducedDocumentSchema).default([]),
    toAssignApprovers: array().of(ReducedDocumentSchema).default([]),
  }),
});

export interface UserTodos extends yup.InferType<typeof UserTodosSchema> {}

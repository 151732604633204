import {
  Checkbox,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { SelectElement, StepValue } from "@types";
import { isArray } from "lodash";
import React from "react";

type Props = {
  element: SelectElement;
  value: string;
  disabled?: boolean;
  onChange: (id: string, value: StepValue) => void;
};

export const Select: React.FC<Props> = ({
  disabled,
  onChange,
  value,
  element,
}) => {
  const stringOrArrayValue =
    typeof value === "string" && element.options.multiple
      ? value.trim().split(",")
      : value;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    let value = event.target.value;

    if (element.options.multiple && isArray(value)) {
      const arrayValue = value as string[];
      value = arrayValue.filter((v) => v !== "").join(",");
    }

    onChange(element.id, { answer: value as string });
  };

  return (
    <FormControl className="flex w-full flex-col ">
      <TextField
        disabled={disabled}
        select
        SelectProps={{
          multiple: element.options.multiple,
          renderValue: (stringOrArrayValue: unknown) => {
            let value;

            if (typeof stringOrArrayValue === "string")
              value = stringOrArrayValue;
            if (Array.isArray(stringOrArrayValue))
              value = stringOrArrayValue.join(", ");
            return value;
          },

          onChange: handleChange,
        }}
        hiddenLabel
        id={element.id}
        value={stringOrArrayValue}
      >
        {element.options.options.map((name) => (
          <MenuItem key={name} value={name}>
            {element.options.multiple && (
              <Checkbox checked={stringOrArrayValue.indexOf(name) > -1} />
            )}

            {name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default Select;

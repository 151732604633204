import { theme } from "@config";
import { BaseUser } from "@models";
import { Edit } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";

export default function BasicTable({
  rows,
  onClick,
  disableCellSelection = false,
}: {
  rows: BaseUser[];
  onClick: (user?: BaseUser) => void;
  disableCellSelection?: boolean;
}) {
  const defineRows = rows.map((user, idx) => ({
    id: idx + 1,
    name: (
      <div className="flex items-center gap-2.5">
        <div>
          {user.firstName} {user.lastName}
        </div>
        <Edit
          fontSize="small"
          cursor="pointer"
          data-testid={`edit-${user.email}`}
          style={{ color: theme.palette.grey[500] }}
          onClick={() => onClick(user)}
        />
      </div>
    ),
    createdAt: user.createdAt.toDateString(),
    email: user.email,
    status: (
      <Chip
        className="text-sm"
        label={`${user.authId ? "Active" : "Pending"}`}
        color={user.authId ? "success" : "warning"}
        size="tiny"
      />
    ),
  }));

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGridPremium
        rows={defineRows}
        columnVisibilityModel={{
          id: false,
        }}
        columns={[
          {
            field: "id",
            headerName: "ID",
            hideable: true,
          },
          {
            field: "name",
            headerName: "Name",
            renderCell: (params) => params.value,
            flex: 1,
          },
          {
            field: "email",
            headerName: "Email",
            flex: 1,
          },
          {
            field: "createdAt",
            headerName: "Created At",
            flex: 1,
          },
          {
            field: "status",
            headerName: "Status",
            renderCell: (params) => params.value,
            width: 100,
          },
        ]}
        sx={{
          lineHeight: 2,
          fontSize: "1rem",
          ".MuiDataGrid-overlayWrapper": {
            height: 100,
          },
          ".MuiDataGrid-cell:not(:last-child), .MuiDataGrid-columnHeader:not(:last-child)":
            {
              borderRight: "none",
            },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "8px",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "9px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          ".MuiDataGrid-editInputCell": {
            fontSize: "1rem",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          ".MuiDataGrid-cellContent, .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
          },
          ".MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          },
          "& .MuiDataGrid-filler": {
            borderBottom: "none",
          },
          ".MuiDataGrid-cell.MuiDataGrid-cellEmpty": {
            border: "none",
          },
          ".MuiDataGrid-container--top [role=row]": {
            backgroundColor: "transparent",
          },
          ...(disableCellSelection && {
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }),
        }}
        ignoreValueFormatterDuringExport
        getRowHeight={() => "auto"}
        autoHeight
        autosizeOnMount
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterRowCount
        hideFooter
        scrollbarSize={0}
        disableColumnReorder
      />
    </div>
  );
}

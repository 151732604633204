import config from "./config";
import ARTICLES from "./helpcenter/articles";

export type Config = typeof config;
export default config;
export * from "./assistant";
export { config as ASSISTANT_CONFIG, templatesWithUpload } from "./assistant";
export * from "./helpcenter/articles";
export { ROUTES } from "./navigation/routes";
export * from "./request-keys";
export * from "./roadmap";
export { theme } from "./theme";
export { ARTICLES };

export const ALWAYS_AVAILABLE_CARDS = [
  "device-basic-characteristics",
  "software-processes",
  "device-classification",
  "establish-risk-management-system",
  "establish-quality-management-system",
  "user-needs",
];

export const DEVICE_CHARACTERISTICS_STATEMENTS = {
  hasHumanContact: "The product comes in contact with the human body.",
  hasSoftware: "The product uses software.",
  isSoftwareOnly: "The product is a software only application.",
  incorporatesAI: "The product incorporates artificial intelligence.",
  hasHardware: "The product has hardware.",
  isImplantable: "The product is implantable.",
};

export const imageElementsIds = ["software-architecture-diagram"];

import CloseIcon from "@mui/icons-material/Close";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useInviteUserMutation } from "../../../../../../../hooks";
import { emptyUser } from "../../../utils";
import { InviteNewUserProps, UserToInvite } from "./InviteNewUser.types";

const invitedUserMessageDictionary = (inviteeFirstName: string) => ({
  success: {
    text: `Invitation has been sent to ${inviteeFirstName}'s email inbox. You can now select ${inviteeFirstName} as an assignee. However they will need to sign up using the link in their email in order to approve the document.`,
    color: "green",
  },
  conflict: {
    text: "An error occurred, are you sure you entered the correct email?",
    color: "red",
  },
  error: {
    text: "Something went wrong, please try again later.",
    color: "red",
  },
});

export const InviteNewUser = ({
  users,
  setUsers,
  orgId,
}: InviteNewUserProps) => {
  const [userToInvite, setUserToInvite] = useState<UserToInvite>(emptyUser);
  const [expanded, setExpanded] = useState(false);
  const [showInvitedUserMsg, setShowInvitedUserMsg] = useState<{
    text: string;
    color: string;
  } | null>(null);
  const [isInvitationLoading, setIsInvitationLoading] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setUserToInvite(emptyUser);
  };

  const inviteUserMutation = useInviteUserMutation(orgId);

  const handleInviteUser = async () => {
    setIsInvitationLoading(true);

    const waitMutation = await inviteUserMutation.mutateAsync({
      inviteeEmail: userToInvite.email.trim(),
      inviteeFirstName: userToInvite.firstName.trim(),
      inviteeLastName: userToInvite.lastName.trim(),
    });

    const isMutationConflict = waitMutation.status === 409;
    const isMutationSuccessful = waitMutation.status === 200;

    const inviteeFirstName = userToInvite.firstName.trim();

    let message = invitedUserMessageDictionary(inviteeFirstName).success;

    if (isMutationConflict) {
      message = invitedUserMessageDictionary(inviteeFirstName).conflict;
    }

    if (!isMutationSuccessful && !isMutationConflict) {
      message = invitedUserMessageDictionary(inviteeFirstName).error;
    }

    setShowInvitedUserMsg(message);
    setExpanded(false);

    if (waitMutation.data && isMutationSuccessful) {
      setIsInvitationLoading(false);
      setUsers && setUsers([...users, waitMutation.data]);
    }
  };

  const hasUserFilledAllInputs =
    !!userToInvite?.email &&
    !!userToInvite?.firstName &&
    !!userToInvite?.lastName;

  return (
    <div className="flex flex-col gap-2 mt-2">
      {!expanded && (
        <div>
          <Button
            onClick={handleExpandClick}
            variant="outlined"
            startIcon={<PersonAddAltOutlinedIcon />}
          >
            Invite User
          </Button>
          {showInvitedUserMsg && (
            <Typography
              variant="subtitle2"
              className="p-0 mt-1"
              color={showInvitedUserMsg.color}
            >
              {showInvitedUserMsg.text}
            </Typography>
          )}
        </div>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Card
          sx={{
            "&.MuiPaper-root": {
              boxShadow: "none",
            },

            "& .MuiCardContent-root": {
              border: "1px solid rgba(0,0,0,0.14)",
              padding: "12px",
            },
          }}
        >
          <CardContent className="p-3">
            <div className="flex justify-between col-span-full items-center mb-3">
              <Typography variant="body1" fontWeight="bold">
                Invite User
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={handleExpandClick}
                // className="p-0"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <TextField
                label="First Name"
                size="small"
                fullWidth
                value={userToInvite.firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUserToInvite({
                    ...userToInvite,
                    firstName: event.target.value,
                  })
                }
                style={{ padding: 0 }}
              />
              <TextField
                label="Last Name"
                size="small"
                fullWidth
                value={userToInvite.lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUserToInvite({
                    ...userToInvite,
                    lastName: event.target.value,
                  })
                }
              />
              <TextField
                label="Email"
                size="small"
                fullWidth
                className="col-span-full"
                value={userToInvite.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUserToInvite({
                    ...userToInvite,
                    email: event.target.value,
                  })
                }
              />
              <Button
                variant="contained"
                className="col-span-full flex gap-2 items-center justify-center"
                fullWidth
                disabled={!hasUserFilledAllInputs}
                onClick={handleInviteUser}
                size="large"
              >
                Invite
                {isInvitationLoading && (
                  <CircularProgress
                    size={14}
                    sx={{
                      color: "#fff",
                    }}
                  />
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Collapse>
    </div>
  );
};

export default InviteNewUser;

import * as yup from "yup";
import { array, boolean, date, mixed, number, object, string } from "yup";

export enum OrganizationRole {
  ADMIN = "ADMIN",
}

export enum EntitlementType {
  DEVICE_LIMIT = "DEVICE_LIMIT",
  USER_LIMIT = "USER_LIMIT",
  CONSULTING = "CONSULTING",
  ALL_DOCUMENTS_UNLOCKED = "ALL_DOCUMENTS_UNLOCKED",
}

export enum BillingInterval {
  MONTHLY = "month",
  YEARLY = "year",
}

export type SimpleSubscription = {
  product: Plan;
  billingInterval: BillingInterval;
  quantity: number;
};

export const EntitlementSchema = object({
  id: string().uuid().required(),
  type: mixed<EntitlementType>()
    .oneOf(Object.values(EntitlementType))
    .required(),
  value_bool: boolean().nullable(),
  value_int: number().integer().nullable(),
});

export const ProductSchema = object({
  id: string().uuid().required(),
  name: string().required(),
  stripeProductId: string().required(),
  entitlements: array(EntitlementSchema),
});

export const SubscriptionItemSchema = object({
  id: string().uuid().required(),
  subscriptionId: string().uuid().required(),
  product: ProductSchema.required(),
  productId: string().uuid().required(),
  productQuantity: number().integer().nullable(),
  interval: string().oneOf(Object.values(BillingInterval)).required(),
});

export const SubscriptionSchema = object({
  id: string().uuid().required(),
  organizationId: string().uuid().required(),
  status: string().required(),
  stripeSubscriptionId: string().required(),
  subscriptionItems: array(SubscriptionItemSchema).required(),
});

export const OrganizationSchema = object({
  id: string().uuid().required(),
  name: string().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
  subscription: SubscriptionSchema.nullable(),
  stripeCustomerId: string().nullable(),
});

export const BaseUserSchema = object({
  id: string().required(),
  email: string().email().required(),
  authId: string().nullable(),
  firstName: string().nullable(),
  lastName: string().nullable(),
  organizationId: string().uuid().required(),
  organizationRole: array(
    mixed<OrganizationRole>().oneOf(Object.values(OrganizationRole))
  ).min(1),
  createdAt: date().required(),
  updatedAt: date().required(),
});

export interface BaseUser extends yup.InferType<typeof BaseUserSchema> {}

export const UserSchema = object({
  onboarding: object({
    id: string().uuid(),
    userId: string().uuid(),
    callScheduled: boolean().required(),
    createdAt: date().required(),
    updatedAt: date().required(),
  }).required(),
  usage: object({
    id: string().uuid(),
    userId: string().uuid(),
    suggestionCount: number().required(),
    createdAt: date().required(),
    updatedAt: date().required(),
  }).required(),
  organization: OrganizationSchema.required(),
}).concat(BaseUserSchema);

// enum SubscriptionStatus {
//   INCOMPLETE = "incomplete",
//   INCOMPLETE_EXPIRED = "incomplete_expired",
//   TRIALING = "trialing",
//   ACTIVE = "active",
//   PAST_DUE = "past_due",
//   CANCELED = "canceled",
//   UNPAID = "unpaid",
//   PAUSED = "paused",
// }

export enum Plan {
  PREMIUM = "PREMIUM",
  PREMIUM_PLUS = "PREMIUM_PLUS",
  FREE = "FREE",
}

// export const SubscriptionSchema = object({
//   status: string()
//     .oneOf(Object.values(SubscriptionStatus))
//     .required()
//     .nullable(),
//   deviceCount: number().required().nullable(),
//   cycle: string().oneOf(Object.values(BillingCycle)).required().nullable(),
//   plan: string().oneOf(Object.values(Plan)).required(),
// });

export interface Organization
  extends yup.InferType<typeof OrganizationSchema> {}
export interface Entitlement extends yup.InferType<typeof EntitlementSchema> {}
export interface Subscription
  extends yup.InferType<typeof SubscriptionSchema> {}
export interface SubscriptionItem
  extends yup.InferType<typeof SubscriptionItemSchema> {}
export interface Product extends yup.InferType<typeof ProductSchema> {}

export interface Subscription
  extends yup.InferType<typeof SubscriptionSchema> {}

export interface User extends yup.InferType<typeof UserSchema> {}

import { useGetUser } from "@hooks";
import { FolderOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { getFunctionalGroupsToDocsMap } from "@utils";
import { ASSISTANT_CONFIG } from "../../config";
import { FunctionalGroupNames } from "../../config/assistant";
import { Document, User } from "../../stores/models";
import { TEMPLATE_TYPE } from "../../stores/models/template-document";
import { FunctionalGroup } from "../../types";
import TreeViewSkeleton from "./TreeViewSkeleton";
import DocExplorerLeaf from "./components/DocExplorerLeaf";

const generateTree = (deviceId: string, documents: Document[], user: User) => {
  // Group documents by functional group
  const functionalGroupsToDocsMap = getFunctionalGroupsToDocsMap(documents);

  // Generate top layer functional groups first, put document leaves inside them
  return Object.entries(functionalGroupsToDocsMap)
    .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
    .map(([functionalGroup, docs]) => {
      return (
        <TreeItem
          key={functionalGroup}
          itemId={functionalGroup}
          label={FunctionalGroupNames[functionalGroup as FunctionalGroup]}
        >
          {docs
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((document, index) => {
              const docTemplateId = document.name as TEMPLATE_TYPE;

              return (
                <DocExplorerLeaf
                  documents={documents}
                  template={ASSISTANT_CONFIG[docTemplateId]}
                  deviceId={deviceId}
                  key={`${document.name}-${index}-link`}
                  user={user}
                />
              );
            })}
        </TreeItem>
      );
    });
};

export const DocExplorer = ({
  deviceId,
  templateId,
  className = "",
  isLoading,
  documents,
}: {
  deviceId: string;
  templateId?: TEMPLATE_TYPE;
  className?: string;
  isLoading?: boolean;
  documents: Document[] | undefined;
}) => {
  const { data: user } = useGetUser();
  const docIsEmpty = documents?.length === 0;

  return (
    <div
      className={`min-h-[30rem] min-w-[16rem] overflow-auto rounded border border-solid border-slate-300 ${className}`}
    >
      <div className="flex items-center gap-2 border-0 border-b border-solid border-slate-300 p-2">
        <FolderOutlined className="text-base" />
        <Typography variant="body1">Document Explorer</Typography>
      </div>
      <div className="py-2">
        {isLoading || !user ? (
          <TreeViewSkeleton />
        ) : docIsEmpty ? (
          <Typography variant="body2" className="text-center mt-2 opacity-50">
            You don't have any documents yet.
          </Typography>
        ) : (
          <SimpleTreeView
            aria-label="file system navigator"
            defaultExpandedItems={
              templateId && [ASSISTANT_CONFIG[templateId].functionalGroup]
            }
            selectedItems={templateId || ""}
          >
            {documents && generateTree(deviceId, documents, user)}
          </SimpleTreeView>
        )}
      </div>
    </div>
  );
};

import { useUpdateOrgMutation } from "@hooks";
import { useState } from "react";
import { useSnackbar } from "../../Snackbar";

export type UpdateOrgNameProps = {
  updateOrgNameResponse: UpdateOrgNameResponse;
};

export type UpdateOrgNameResponse = {
  setOrgName: (orgName: string) => void;
  isOrgNameFilled: boolean;
  setOpenChangeOrgNameModal: (open: boolean) => void;
  openChangeOrgNameModal: boolean;
  handleCloseChangeOrgNameModal: () => void;
  handleUpdateOrgName: () => void;
  oldName: string;
};

export default function useUpdateOrgName({
  orgId,
  oldName,
}: {
  orgId: string;
  oldName: string;
}): UpdateOrgNameProps {
  const [orgName, setOrgName] = useState<string>("");

  const [openChangeOrgNameModal, setOpenChangeOrgNameModal] = useState(false);
  const { showSnackbar } = useSnackbar();

  const isOrgNameFilled = orgName.length > 0;

  const handleCloseChangeOrgNameModal = () => {
    setOpenChangeOrgNameModal(false);
  };

  const updateOrgNameMutation = useUpdateOrgMutation(orgId);

  const handleUpdateOrgName = async () => {
    const waitMutation = await updateOrgNameMutation.mutateAsync({
      orgName: orgName.trim(),
    });

    const isMutationSuccessful = waitMutation.status === 200;

    !isMutationSuccessful && showSnackbar("Failed to update Organization name");

    setOpenChangeOrgNameModal(false);
  };

  return {
    updateOrgNameResponse: {
      setOrgName,
      isOrgNameFilled,
      setOpenChangeOrgNameModal,
      openChangeOrgNameModal,
      handleCloseChangeOrgNameModal,
      handleUpdateOrgName,
      oldName,
    },
  };
}

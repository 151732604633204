import axios from "axios";
import { ROUTES } from "src/config";
import { authHelper } from "../stores/helpers";
import {
  BillingInterval,
  Device,
  DocumentVersionApproverStatus,
  Plan,
  TEMPLATE_TYPE,
  User,
} from "../stores/models";
import { Onboarding, PatchDocumentVersionData } from "../types";
const axiosClient = axios.create({
  baseURL: window._env_.BACKEND_BASE_URL,
});

axiosClient.interceptors.request.use(
  async (axiosConfig) => {
    let token = null;
    token = await authHelper.getAccessTokenSilently()();

    if (token) {
      // @ts-ignore
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getUser = async () => {
  return axiosClient.get(`user/me`);
};

export const getUserById = async (userId: string) => {
  return axiosClient.get(`user/${userId}`);
};

export const getUserTodos = async (deviceId: string) => {
  return axiosClient.get(`user/me/todos?deviceId=${deviceId}`);
};

export const getDocumentApprovers = async ({
  documentId,
  versionId,
}: {
  documentId: string;
  versionId: string;
}) => {
  return axiosClient.get(
    `/document/${documentId}/version/${versionId}/approver`
  );
};

export const getUsersByOrg = async ({
  orgId,
  skip = 0,
  take = 20,
}: {
  orgId: string;
  skip?: number;
  take?: number;
}) => {
  return axiosClient.get(`user`, {
    params: {
      orgId,
      skip,
      take,
    },
  });
};

export const updateUser = async (id: string, data: Partial<User>) => {
  return axiosClient.patch(`user/${id}`, data);
};

export const updateOrg = async (id: string, data: { name: string }) => {
  return axiosClient.patch(`organization/${id}`, data);
};

export const updateOnboarding = async (data: Partial<Onboarding>) => {
  return axiosClient.patch("user/onboarding", data);
};

export const getDevices = async () => {
  return axiosClient.get("device");
};

export const getDevice = async (id: string) => {
  return axiosClient.get(`device/${id}`);
};

export const postDevice = async (data: {
  name: string;
  description: string;
}) => {
  return axiosClient.post("device", data);
};

export const patchDevice = async (data: Device) => {
  return axiosClient.patch(`device/${data.id}`, {
    ...(data.name && { name: data.name }),
    ...(data.description && { description: data.description }),
  });
};

export const deleteDevice = async (id: string) => {
  return axiosClient.delete(`device/${id}`);
};

export const getRoadmapTasks = async (deviceId: string) => {
  return axiosClient.get(`device/${deviceId}/roadmap/tasks`);
};

export const patchRoadmapTasks = async (
  deviceId: string,
  data: Record<string, boolean>
) => {
  return axiosClient.patch(`device/${deviceId}/roadmap/tasks`, data);
};

export const getTemplateDocument = async (
  deviceId: string,
  type: TEMPLATE_TYPE
) => {
  return axiosClient.get("template-document", {
    params: { deviceId, type },
  });
};

export const getDocument = async (deviceId: string, docId: string) => {
  return axiosClient.get(`/document/${docId}`, {
    params: { deviceId },
  });
};

export const getDocuments = async (deviceId: string) => {
  return axiosClient.get("/document", { params: { deviceId } });
};

export const createDocument = async (
  deviceId: string,
  templateId: TEMPLATE_TYPE
) => {
  return axiosClient.post("/document", {
    deviceId,
    templateId,
  });
};

export const createDocumentVersion = async (
  documentId: string,
  file?: File
) => {
  return axiosClient.post(
    `/document/${documentId}/version`,
    { file },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const uploadFile = async ({ file }: { file: File }) => {
  return axiosClient.post(
    `/file`,
    { file },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const getFile = async ({ fileId }: { fileId: string }) => {
  return axiosClient.get(`/file/${fileId}`, { responseType: "blob" });
};

export const patchDocumentVersion = async (
  documentId: string,
  versionId: string,
  data: PatchDocumentVersionData
) => {
  return axiosClient.patch(
    `/document/${documentId}/version/${versionId}`,
    data
  );
};

export const postDocumentAnswer = async (
  documentId: string,
  versionId: string,
  data: { answer?: string; element: string; answerFileId?: string }
) => {
  return axiosClient.patch(
    `/document/${documentId}/version/${versionId}/answer`,
    data
  );
};

export const postDocumentAnswerSuggestion = async (
  documentId: string,
  versionId: string,
  data: { suggestion: string; element: string }
) => {
  return axiosClient.post(
    `/document/${documentId}/version/${versionId}/suggestion`,
    data
  );
};

export const patchTemplateDocument = async (
  deviceId: string,
  type: TEMPLATE_TYPE,
  content: string
) => {
  return axiosClient.patch(
    `template-document`,
    {
      content,
    },
    { params: { deviceId, type } }
  );
};

export const m2t = async (text: string) => {
  return axiosClient.post("/document/m2t", { text });
};

export const checkout = async (
  plan: Plan.PREMIUM | Plan.PREMIUM_PLUS,
  amount: number,
  cycle: BillingInterval
) => {
  return axiosClient.post("/subscription/checkout", {
    plan,
    amount,
    cycle,
    cancelUrl: window.location.href,
    successUrl: `${window.location.origin}${ROUTES.CHECKOUT_SUCCESS}`,
  });
};

export const getSubscription = async () => {
  return axiosClient.get("/subscription");
};

export const deleteSubscription = async () => {
  return axiosClient.delete("/subscription");
};

export const updateSubscription = async (
  plan: Plan,
  amount: number,
  cycle: BillingInterval
) => {
  return axiosClient.patch("/subscription", {
    plan,
    amount,
    cycle,
  });
};

export const postBillingSession = async () => {
  return axiosClient.post("/subscription/billing", {
    returnUrl: window.location.href,
  });
};

export const postDocumentApprover = async ({
  documentId,
  versionId,
  data,
}: {
  documentId: string;
  versionId: string;
  data: { userId: string };
}) => {
  return axiosClient.post(
    `/document/${documentId}/version/${versionId}/approver`,
    data
  );
};

export const patchApproverStatus = async ({
  documentId,
  versionId,
  status,
}: {
  documentId: string;
  versionId: string;
  status: keyof typeof DocumentVersionApproverStatus;
}) => {
  return axiosClient.patch(
    `/document/${documentId}/version/${versionId}/approver/me`,
    {
      status,
    }
  );
};

export const deleteDocumentApprover = async (
  documentId: string,
  versionId: string,
  approverId: string
) => {
  return axiosClient.delete(
    `/document/${documentId}/version/${versionId}/approver/${approverId}`
  );
};

export const postUserInvite = async (data: {
  inviteeEmail: string;
  inviteeFirstName: string;
  inviteeLastName: string;
}) => {
  return axiosClient.post(`/user/invite`, data);
};
